import React, { FC } from 'react';
import { RadioGroup } from '@material-ui/core';
import { ApplyHeading, ApplyCaption } from '../Label';
import { CustomRadioButton, BlueRadio } from '../CustomRadioButton';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(
  createStyles({
    payMethodSeletctionContainer: {
      margin: '8px 0 0',
      '& .MuiTypography-root': {
        fontSize: 14
      }
    },
    description: {
      color: 'var(--color-text)',
      fontSize: 14,
      margin: '14px 0 0'
    },
    activeRadioBorder: {
      border: '2px solid var(--color-key) !important'
    }
  })
);

interface Props {
  use_og_gas: string;
  pay_method: string;
  updateParameter: (key: string, value: string) => void;
  isOgExternal: boolean;
}

export const PayMethod: FC<Props> = ({
  pay_method,
  updateParameter,
  use_og_gas,
  isOgExternal,
}) => {
  const styles = useStyles({});

  return (
    <>
      <ApplyHeading text={'お支払い方法'} id={'paymethod'} />
      <p className={styles.description}>
        口座振替・クレジットカードをご選択の場合、大阪ガスより申込書を郵送します。
      </p>
      <ApplyCaption
        text={'※お手続きが完了するまでは払込票でのお支払いとなります。'}
      />

      <RadioGroup
        className={styles.payMethodSeletctionContainer}
        aria-label="payMethod"
        name="paymentMethod"
        value={pay_method}
        onChange={e => updateParameter('pay_method', e.target.value)}
      >
        <CustomRadioButton
          className={pay_method === '0' ? styles.activeRadioBorder : ''}
          value={'0'}
          control={<BlueRadio />}
          label="口座振替"
        />
        <CustomRadioButton
          className={pay_method === '1' ? styles.activeRadioBorder : ''}
          value={'1'}
          control={<BlueRadio />}
          label="クレジットカード"
        />
        {!isOgExternal &&
        use_og_gas === '1' && (
          <CustomRadioButton
            className={pay_method === '2' ? styles.activeRadioBorder : ''}
            value={'2'}
            control={<BlueRadio />}
            label="払込票"
          />
        )}
      </RadioGroup>
    </>
  );
};
