import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box',
      fontSize: 14,
      '& h1': {
        color: 'var(--color-text)',
        fontSize: 24,
        margin: '40px 0 0'
      },
      '& h2': {
        color: 'var(--color-text)',
        fontSize: 18,
        margin: '20px 0 0'
      },
      '& h3': {
        color: 'var(--color-text)',
        fontSize: 16,
        fontWeight: 'normal'
      },
      '& table': {
        width: '100%',
        margin: '12px 0 0',
        border: '2px solid #333',
        borderCollapse: 'collapse'
      },
      '& tr': {
        margin: 0,
        textAlign: 'left'
      },
      '& th': {
        color: '#333',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        border: '1px solid #333',
        borderWidth: '0px 1px 1px 0px',
        borderCollapse: 'collapse',
        padding: '16px 8px',
        margin: 0,
        width: '40%'
      },
      '& td': {
        color: 'var(--color-text)',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '20px',
        border: '1px solid #333',
        borderCollapse: 'collapse',
        borderWidth: '0px 0px 1px',
        padding: '16px 8px',
        margin: 0,
        wordBreak: 'break-all',
        width: '60%'
      }
    }
  })
);

const Terms: React.FC = () => {
  const styles = useStyles({});

  return (
    <>
      <div className={styles.container}>
        <h1>サイトポリシー</h1>
        <br />
        <p>
          当サイトは、大阪ガス株式会社 ( 以下、「当社」といいます。) が運営しているサイトです。
          <br />
          当サイトをご利用される前に本規定をお読みいただき、同意された場合のみ本サイトをご利用ください。なお本規定は、予告なく内容を変更させていただく場合がございますので、あらかじめご了承ください。
          <br />
          また、各コンテンツ中に利用規約が設けられているものについては、それぞれの利用規約をよくお読みのうえ、ご利用ください。
        </p>
        <br />
        <h2>免責事項</h2>
        <p>
          1. 当社は、当サイトに掲載する情報について様々な注意を払って掲載しておりますが、内容の正確性、有用性、安全性、その他いかなる保証を行うものでもありません。当サイトに掲載されている情報は、あくまでも掲載時点における情報であり、時間の経過により掲載情報が実際と一致しなくなる場合があります。
          <br />
          当社は一切責任を負いませんので、当サイトは、あくまでご自身の責任においてご利用ください。
          <br />
          2. サイトのデータ等の内容、構成等は、予告なしに変更、修正または中止することがあります。ご了承ください。
        </p>
        <br />
        <h2>著作権について</h2>
        <p>
          当サイトに掲載されるすべての情報は、原則として当社または原著作者その他の権利者が著作権を有しております。 個人的な利用やその他著作権法によって認められる場合を除き、当社の事前の許可なく、これらの情報を使用(複製、改変、配布等を含む)することを禁止します。
        </p>
        <br />
        <h2>個人情報の取扱いについて</h2>
        <p>
          当社ではプライバシーポリシーに基づき、個人情報を適切に取り扱い、管理いたします。
        </p>
        <br />
        <h2>リンクについて</h2>
        <p>
          当サイトへのリンクは、当社が認めた場合を除きお断りいたします。
        </p>
        <br />
        <h2>アクセス履歴情報の収集について</h2>
        <p>
          当サイトでは、ログなどに記録された利用者の皆さまのアクセス情報を、クッキー(Cookie)、Web ビーコン、IP アドレス等を手がかりにして自動的に収集しています。収集した情報は主に、アクセス状況を把握するために利用したり、サーバで発生した問題の原因を突き止め解決したり、サイトやサービスをよりよく管理したりするのに利用します。
        </p>
        <br />
        <h2>クッキーについて</h2>
        <p>
          クッキーとは、統計的な分析等や、お客さまのコンピュータの識別、利用履歴・入力内容の保持を行うために広く利用されている技術です。
          <br />
          当サイトでは、クッキーを、当サイトの利用状況分析・利便性向上やマーケティングに利用しており、当社のお客さま情報と関連付ける場合がございます。
          <br />
          なお、記録される情報には、お客さまの氏名、住所、電話番号、カード情報など個人を特定する情報は一切含まれておらず、お客さまのコンピュータへ直接的な悪影響を及ぼすことはございません。
          <br />
          また、ブラウザの設定を変更することによりクッキーの利用を拒否することができますが、当サイトの一部機能がご利用頂けなくなる場合がございます。
        </p>
        <br />
        <h2>推奨環境</h2>
        <p>
          当サイトをより快適にご覧いただくために以下の環境を推奨します。
          <br />
          また、一部のコンテンツをご覧いただく際に、最新のブラウザやプラグインソフトを必要とする場合があります。ご了承ください。
          <br />
          <br />
          【WEB ブラウザ】
          <br />
          「Google Chrome」「Safari」「Microsoft Edge」の最新バージョンまたは安定版
        </p>
        <br />
        <h2>■準拠法について</h2>
        <p>
          当サイトのご利用ならびに本規定の運用に関し、別段の定めがない限り、日本国法に準拠するものとします。
        </p>
      </div>
    </>
  );
};

export default Terms;
