import React from 'react';

/**
 * 
 * ベネフィットワンの利用規約
 * プランによっては使わなくなる可能性があるので外出し
 * @returns 
 */
const CouponTerm: React.FC = () => {
    return (
      <>
        <h1 id="coupon">優待・割引サービス（めちゃとクーポン）利用規約</h1>
        <h2>第１条（目的）</h2>
        <p>
          優待・割引サービス（めちゃとクーポン）利用規約（以下「本規約」といいます。）は、大阪ガス株式会社（以下「当社」といいます。）が提供する優待・割引サービス（めちゃとクーポン）（以下「本サービス」といいます。）の利用に関して定めるものです。
        </p>
        <h2>第２条（サービスの内容）</h2>
        <p>
          本サービスは、トラベル、グルメ、レジャー、エンターテインメント等の様々なサービスに加え、Daigasグループ会社が提供するフィットネスクラブ「コ・ス・パ」や料理教室「大阪ガスクッキングスクール」等のサービスを市価または定価と比べ割安に利用できるサービスです。
        </p>
        <h2>第３条（お客さまの定義）</h2>
        <p>
          本規約において使用する「お客さま」とは、本規約の内容を確認し、同意したうえで、当社所定の申込方法により、申込み手続きを行い、当社がその申込みを承諾した方をいいます。
        </p>
        <h2>第４条（委託）</h2>
        <p>
          当社は、本サービスのサービス提供を、株式会社ベネフィット・ワンに委託します。
        </p>
        <h2>第５条（加入要件）</h2>
        <p>
          本サービスは、お客さまがスマモル賃貸プランの標準採用物件に入居する場合で、物件の管理等を行う事業者（以下「管理会社等」といいます。）からの紹介を受けた場合に加入することができます。
        </p>
        <h2>第６条（本サービスの申込み、利用）</h2>
        <p>
          (1)お客さまは、本サービスを当社に申込みする際には、あらかじめ本規約の内容を確認し、同意のうえ、当社所定の申込方法に則って申し込むものとします。
          <br />
          (2)お客さまは、当社が発行したID（以下「ID」といいます）およびパスワードでログインする
          <br />
          ことにより、ベネフィット・ワンが提供する「ベネフィット・ステーション」を利用できます。
          <br />
          (3)「ベネフィット・ステーション」の利用については、ベネフィット・ワンが別途定める「ベネフィット・ステーション（個人）会員規約」が適用され、お客さまはこれを遵守するものとします。
          <br />
          (4)お客さまの「ベネフィット・ステーション」の利用等に関して、ベネフィット・ワンとの間に生じた紛議については、お客さまとベネフィット・ワンとの間で解決するものとし、その紛議が当社の責に帰すべき事由により生じた場合を除いて、当社は一切責任を負いません。
        </p>
        <h2>第７条（本サービスの開始時期）</h2>
        <br />
        本サービスは、一部のサービスを除き、原則としてお客さまのご入居日からサービスを開始します。お客さまのお申込みが遅れたことにより、サービスの提供開始が遅れた場合でも、当社はその責任を負いません。
        <br />
        <h2>第8条（提供期間）</h2>
        <br />
        (1)本サービスの提供期間は、原則として入居日から2年間となります。ただし、お客さまにスマモル賃貸プランのご契約がある場合、その契約が続く限り本サービスの提供は継続されます。
        <br />
        上記にかかわらず、お引越しに伴う解約の場合、または入居日から2年を経過する前にお引越しされる場合は、当該お引越し日をもってサービスの提供は終了されるものとします。
        <br />
        (2)当社は、お客さまが第10条または第16条に違反していると当社またはベネフィット・ワンが判断した場合には、本サービスの提供を中止できるものとし、お客さまは予めこれを承諾するものとします。
        <br />
        <h2>第9条（サービス内容の変更）</h2>
        <br />
        当社およびベネフィット・ワンは、お客さまの承諾を得る事なく、本サービスの全部または一部の変更または追加が出来るものとします。これにより、お客さまが本サービスの一部割引・優待を利用できなかったことに関し、当社およびベネフィット・ワンは何らの責任も負いません。
        <br />
        <h2>第10条（本サービスにおける遵守事項、禁止事項）</h2>
        <br />
        お客さまは、本サービスの利用にあたって次の各号に該当する行為を行ってはならないものと
        <br />
        します。
        <br />
        ①本サービスを営利目的で利用する行為、または本サービスを通じて営利を得る目的の行為。
        <br />
        ②本規約および諸規程に記載されている内容を超えるサービス提供を求める行為または本規約を逸脱する行為及びそれに類する行為。
        <br />
        ③本サービスに関係する個人・法人・団体を誹謗中傷する行為。
        <br />
        ④本サービスに関係する個人・法人・団体が保有する著作権、財産権、その他の権利を侵害す
        <br />
        る行為、または侵害する恐れがある行為。
        <br />
        ⑤本サービスに関係する個人・法人・団体に不利益または損害を与える行為または与える恐れのある行為。
        <br />
        ⑥犯罪、反社会的行為を含む公序良俗に反する行為またはそれに関連する行為。
        <br />
        ⑦法律に違反する行為または違反の恐れのある行為。
        <h2>第11条（提供の拒否）</h2>
        <br />
        当社およびベネフィット・ワンは、以下のいずれかの事由に該当すると判断した場合、お客さまに対して、本サービスの提供を拒否することができるものとします。
        <br />
        ①お客さま本人からの本サービスの利用申込みであることを確認できない場合。
        <br />
        ②台風・大雨・暴風・豪雪等の異常気象、地震・噴火等の天災地変もしくは戦争・暴動または公権力の行使等により、本サービスを提供することが困難または危険が伴うことが予測される場合。
        <br />
        ③本サービスの対応内容を逸脱する場合。
        <br />
        ④委託会社が本サービスを提供することが困難であると判断する場合。
        <br />
        ⑤本サービスを提供することにより、第三者の所有物の損壊、第三者の権利または利益の制限あるいは第三者に損害が生じることが想定される場合。
        <br />
        ⑥当社または委託会社のシステムの定期的、緊急的な保守点検が必要な場合またはシステムに障害が発生した場合。
        <br />
        ⑦前各号以外でも、社会通念上、本サービスの提供が困難であるとみられる場合。
        <br />
        <h2>第12条（個人情報）</h2>
        <br />
        当社は、ガス・電気・熱等の各種エネルギーや当社の商品・サービスをお客さまにご利用いただくにあたり、当契約の申込み受付等により、当社が直接または業務委託先等を通じて、お客さまの個人情報（お客さまの氏名・住所・電話番号等）を取得します。これらの個人情報は、以下の目的に利用します。
        <br />
        ①エネルギー供給およびその普及拡大
        <br />
        ②エネルギー供給設備工事
        <br />
        ③エネルギー供給設備・消費機器（厨房・給湯・空調等）の修理・取替・点検等の保安活動
        <br />
        ④漏洩・火災自動通報、供給の遠隔遮断等のエネルギー供給事業に関連するサービスの提供
        <br />
        ⑤エネルギー消費機器・警報器等の機器および住宅設備の販売（リース・レンタル等を含む）、設置、修理・点検、商品開発、アフターサービス
        <br />
        ⑥上記各種事業に関するサービス・製品のお知らせ・PR・調査・データ集積・分析、研究開発
        <br />
        ⑦その他上記①～⑥に附随する業務の実施
        <br />
        なお、当社は、上記の業務を円滑に進めるため、金融機関、クレジットカード会社、コンビニエンスストア、債権回収会社、情報処理会社、保証サービス会社、協力会社（サービスショップ、メンテサービス会社、工事会社等）に業務の一部を委託することがあります。その際、当社からこれらの業務委託先に必要な範囲で個人情報を提供することがあります。その場合、当社は、業務委託先との間で取り扱いに関する契約を結ぶ等、適切な監督を行います。
        <br />
        <h2>第13条（損害賠償の制限）</h2>
        <br />
        本サービスの利用によりお客さまが何らかの損害を被った場合、それが当社およびベネフィット・ワンの故意または重過失により発生したものでない限り、当社およびベネフィット・ワンは当該損害を賠償する責任を負わないものとします。
        <br />
        <h2>第14条（通知）</h2>
        <br />
        当社は、本サービスに関するお客さまへの通知を当社のウェブサイト上にその内容を掲載することをもって、本サービスに関する通知に代えることができるものとします。
        <br />
        この場合、当社が当該通知内容を当社のウェブサイト上に掲載した時点をもって、お客さまに対して、当該通知がなされたものとみなします。
        <br />
        <h2>第15条（変更の届出）</h2>
        <br />
        (1)
        お客さまは、氏名、住所、電話番号、メールアドレス、その他本サービスの利用に伴い当社へ届け出た内容に変更があった場合は、速やかに当社所定の方法で当社に届け出るものとします。なお、お客さまが届出内容の変更があったにもかかわらず、当社に届出をしない場合（当社への届出後、当社がその変更内容を確認できるまでの間を含みます。）、本規約に定める当社からの通知については、当社がお客さまから届出を受けている連絡先へ発信したことにより、通知を行ったものとみなします。
        <br />
        (2)
        前項の届出があった場合、当社は、その届出のあった事実を確認するための書類の提示又は提出をお客さまに求める場合があります。お客さまはこれに応じるものとします。
        <br />
        <h2>第16条（反社会的勢力の排除）</h2>
        <br />
        (1)お客さまは、次の各号のいずれかにも該当しないこと、かつ将来にわたっても該当しな
        <br />
        いことを表明し、保証するものとします。
        <br />
        ①自らが、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、総会屋、社会運動等標ぼうゴロその他これに準じるもの（以下総称して「暴力団員等」といいます。）であること。
        <br />
        ②お客さまが自らもしくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもって取引を行う等、暴力団員等を利用していると認められる関係を有すること。
        <br />
        ③お客さまが暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること。
        <br />
        (2)
        お客さまは、自ら又は第三者を利用して次の各号のいずれか一つでも該当する行為を行わないことを保証するものとします。
        <br />
        ①暴力的な要求行為 ②不当な要求行為
        <br />
        ③取引に関して、脅迫的な言動をし、又は暴力を用いる行為
        <br />
        ④風説を流布し、偽計を用い、又は威力を用いて、当社の信用を毀損し、又は当社の業務を妨害する行為
        <br />
        ⑤その他前各号に準ずる行為
        <h2>第17条（規約の変更）</h2>
        <br />
        本規約およびその他の諸規定は、適宜追加、変更、廃止等の改定を行います。この場合、当社は、改定日の一ヶ月前に、第14条に従い、当該改定の内容をお客さまに通知するものとします。
        <br />
        <h2>第18条（譲渡禁止等）</h2>
        <br />
        お客さまは、お客さまの権利について、第三者に譲渡、売買、質権の設定その他の担保に供する等の行為はできないものとします。
        <br />
        <h2>第19条（合意管轄）</h2>
        <br />
        お客さまおよび当社は、本規約に関連した訴訟を行う場合は、大阪簡易裁判所および大阪地方裁判所を第一審の専属的合意管轄裁判所とすることに合意します。
        <br />
        <h2>第20条（準拠法）</h2>
        <br />
        本規約の成立、効力、解釈及び履行については、日本国法に準拠するものとします。
        <br />
        <h2>第21条（その他）</h2>
        <br />
        本規約に関する疑義または本規約に定めのない事項については、お客さまおよび当社の双方が誠意をもって協議し、円満に解決を図るものとします。
      </>
    );
  };
  
  export default CouponTerm;
  