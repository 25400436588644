import React, { useEffect, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AccountFormHeading } from '../Label';
import { CustomTextField } from '../inputs/CustomTextField';
import {
  mapDispatchToProps,
  mapStateToProps
} from '../../container/validation';
import { useHistory } from 'react-router-dom';
import { Path } from '../../constants/path';
import { Loading } from '../atoms/Loading';
import { SignupMethod } from '../../modules/signUp/types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      paddingBottom: 30,
      '@media (min-width:768px)': {
        width: 295,
        margin: '0 auto'
      }
    },
    caption: {
      color: 'var(--color-gray-3)',
      textAlign: 'center',
      lineHeight: '20px',
      fontSize: 14
    },
    codeForm: {
      backgroundColor: 'var(--color-white)',
      margin: 0,
      width: '100%',
      '& input': {
        padding: '15px 14px',
        textAlign: 'left',
        fontSize: '14px !important',
        '&::placeholder': {
          fontSize: '14px !important'
        }
      },
      '& .MuiOutlinedInput-root.Mui-error': {
        '& input': {
          backgroundColor: 'var(--color-white)'
        }
      },
      '& p': {
        background: 'var(--color-background-gray)',
        margin: 0,
        padding: '8px 14px 0'
      }
    },
    button: {
      margin: '16px 0 0',
      width: '100%',
      color: 'white',
      background: 'var(--color-key)'
    },
    errorMessage: {
      color: '#E91E63',
      fontSize: 12,
      margin: '4px 0 -19px',
      textAlign: 'left'
    },
    loadingContainer: {
      marginTop: 64
    },
    loadingCaption: {
      color: 'var(--color-key)',
      marginTop: 20,
      fontSize: 16,
      textAlign: 'center'
    }
  })
);

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ValidationTemplate: React.FC<Props> = ({
  status,
  submitCode,
  initSubmitStatus,
  signupMethod
}) => {
  const styles = useStyles({});
  const history = useHistory();
  const [code, setCode] = useState<string>('');

  const handleChange = (value: string) => {
    // 認証コードの長さ、メールなら4桁、SMSなら6桁
    const AUTHENTICATION_CODE_LENGTH =
      signupMethod === SignupMethod.Email ? 4 : 6;
    if (value.length < AUTHENTICATION_CODE_LENGTH + 1) {
      setCode(value);
      if(status !== 'not_submit') {
        initSubmitStatus();
      }
    }

    if (
      value.length === AUTHENTICATION_CODE_LENGTH &&
      status !== 'submit_loading'
    ) {
      submitCode(value);
      setCode('');
      return;
    }
  };

  useEffect(() => {
    if (status === 'submit') {
      history.push(Path.account.completed);
    }
  }, [history, status]);

  return (
    <div className={styles.container}>
      {status !== 'submit_loading' && (
        <>
          <AccountFormHeading text={'Bitkeyアカウント作成'} />
          <p className={styles.caption}>
            {signupMethod === SignupMethod.Email
              ? '入力したメールアドレス宛に'
              : '入力した電話番号宛に'}
            <br />
            認証コードを送信しました。
            <br />
            下のフォームにご入力ください
          </p>
          <CustomTextField
            className={styles.codeForm}
            placeholder="認証コードを入力"
            margin="normal"
            value={code}
            onChange={e => handleChange(e.target.value)}
            autoFocus
            error={status === 'error'}
            helperText={status === 'error' ? '認証コードが一致しません' : ''}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
          />
        </>
      )}
      {status === 'submit_loading' && (
        <div className={styles.loadingContainer}>
          <Loading />
          <p className={styles.loadingCaption}>連携中です...</p>
        </div>
      )}
    </div>
  );
};

export default ValidationTemplate;
