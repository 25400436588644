import { connect } from 'react-redux';
import { AppState } from '../store';
import * as module from '../modules/property';
import ValidationScreen from '../screens/account/ValidationScreen';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import * as Actions from '../modules/signUp/actions';

export const mapStateToProps = (state: AppState) => {
  return {
    hasElectricContract: module.Selectors.getProperty(state)
      .hasElectricContract,
    status: state.signUpForm.status
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => ({
  backStatus: () => dispatch(Actions.clearSignupStatus())
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidationScreen);
