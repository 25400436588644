import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../inputs/CustomTextField';

const useStyles = makeStyles(
  createStyles({
    textField: {
      margin: '8px 0 0',
      width: '100%',
      '& input': {
        padding: '16px 14px',
        fontSize: '14px !important',
        '&::placeholder': {
          fontSize: '14px !important'
        }
      }
    }
  })
);

interface Props {
  id?:string,
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  error?: boolean;
  helperText?: string;
}

export const ApplyTextField: React.FC<Props> = ({
  id,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  helperText
}) => {
  const styles = useStyles({});

  return (
    <CustomTextField
      id={id}
      className={styles.textField}
      placeholder={placeholder}
      margin={'normal'}
      value={value}
      error={error}
      helperText={helperText}
      onChange={e => onChange(e.target.value)}
      onBlur={e => { if (onBlur) onBlur(e.target.value) }}
      InputLabelProps={{
        shrink: true
      }}
      variant="outlined"
    />
  );
};
