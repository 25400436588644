import React, { FC, useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, InputAdornment, IconButton } from '@material-ui/core';
import { Path } from '../constants/path';
import { AccountContainer } from './AccountContainer';
import { AccountFormHeading } from './Label';
import { CustomTextField } from './inputs/CustomTextField';
import { mapStateToProps, mapDispatchToProps } from '../container/signInForm';
import { useHistory } from 'react-router-dom';
import bitleyLogo from '../static/images/bitkeyLogo.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import StringUtils from '../utils/string-utils';

const useStyles = makeStyles(() =>
  createStyles({
    formContainer: {
      '@media (min-width:768px)': {
        width: 295,
        margin: '0 auto'
      }
    },
    textField: {
      marginTop: 12,
      width: '100%',
      '& input': {
        background: 'white',
        padding: '16px 14px',
        fontSize: '14px !important',
        '&::placeholder': {
          fontSize: '14px !important'
        }
      },
      '& p': {
        fontWeight: 'bold',
        color: 'var(--color-error) !important',
        margin: '8px 0 0 !important'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        backgroundColor: 'var(--color-white)',
        paddingRight: '4px'
      }
    },
    caption: {
      color: 'var(--color-text)',
      fontSize: 12,
      lineHeight: '17px',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    captionLink: {
      color: 'var(--color-key)',
      textDecoration: 'none'
    },
    button: {
      margin: '16px 0 0',
      width: '100%',
      height: 32,
      padding: '0',
      fontSize: 12,
      lineHeight: '32px',
      color: 'white',
      textTransform: 'none',
      background: 'var(--color-bitkey)',
      '&:disabled': {
        backgroundColor: '#BDBDBD',
        color: 'var(--color-white)'
      },
      '&.MuiButton-root:hover': {
        backgroundColor: 'var(--color-bitkey)'
      },
      '& img': {
        width: 14,
        marginRight: 8
      }
    },
    passwordForget: {
      fontSize: 12,
      lineHeight: '18px',
      color: 'var(--color-gray-3)',
      textAlign: 'center',
      margin: '16px 0 0',
      textDecoration: 'none',
      display: 'block'
    }
  })
);

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const SignInForm: FC<Props> = ({
  identifyValue,
  identifyValueStatus,
  password,
  passwordStatus,
  status,
  updateSigninIdentifyValue,
  updateSigninPassword,
  validateIdentifyValue,
  validatePassword,
  submitLoginForm,
  property,
  getIds,
  getProperty
}) => {
  const styles = useStyles({});
  const history = useHistory();

  const [fieldIdentifyValue, setFieldIdentifyValue] = useState('');
  const [fieldPassword, setFieldPassword] = useState('');
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);

  useEffect(() => {
    if (status === 'signin') {
      history.push(Path.application.url);
    }
  }, [status, history]);
  useEffect(() => {
    if (property.allSpaceId === '') {
      getIds();
    }

    if (property.allSpaceId !== '' && property.address === '') {
      getProperty();
    }
  }, [property, getIds, getProperty]);

  const handleIdentifyValueChange = (e: string) => {
    setFieldIdentifyValue(e);
    updateSigninIdentifyValue(e);
  };

  const handlePasswordChange = (p: string) => {
    setFieldPassword(p);
    updateSigninPassword(p);
  };

  const blurIdentifyValueField = () => {
    const phoneRegex = /^0{1}\d{8,13}$/;
    if (identifyValue === '') {
      validateIdentifyValue('empty');
    } else if (
      StringUtils.isEmail(identifyValue) ||
      phoneRegex.test(identifyValue)
    ) {
      validateIdentifyValue('valid');
    } else {
      validateIdentifyValue('invalid');
    }
  };

  const identifyValueErrorMessage = () => {
    if (identifyValueStatus === 'invalid') {
      return '有効なメールアドレスまたは電話番号を入力してください';
    } else if (status === 'not_found' || status === 'auth_failed') {
      return '';
    }
    return '';
  };

  const blurPasswordField = () => {
    if (password === '') {
      validatePassword('empty');
    } else if (password.length < 8) {
      validatePassword('invalid');
    } else {
      validatePassword('valid');
    }
  };

  const passwordErrorMessage = () => {
    if (passwordStatus === 'invalid') {
      return '8〜56文字で有効なパスワード入力してください';
    } else if (status === 'auth_failed' || status === 'not_found') {
      return 'メールアドレスまたはパスワードに誤りがあります';
    }
    return '';
  };

  const validateForm = () =>
    !(identifyValueStatus === 'valid' && passwordStatus === 'valid');

  const handleClickShowPassword = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  const handleMouseDownPassword = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  return (
    <AccountContainer>
      <form className={styles.formContainer}>
        <AccountFormHeading text={'Bitkeyアカウントでログイン'} />
        <CustomTextField
          className={styles.textField}
          value={fieldIdentifyValue}
          placeholder="メールアドレスまたは電話番号"
          autoComplete="email"
          margin="normal"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          error={
            status === 'not_found' ||
            status === 'auth_failed' ||
            status === 'invalid' ||
            identifyValueStatus === 'invalid'
          }
          helperText={identifyValueErrorMessage()}
          onBlur={blurIdentifyValueField}
          onChange={e => handleIdentifyValueChange(e.target.value)}
        />
        <CustomTextField
          className={styles.textField}
          placeholder="パスワード"
          type={isHiddenPassword ? 'password' : 'text'}
          value={fieldPassword}
          margin="normal"
          autoComplete="current-password"
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          onBlur={blurPasswordField}
          error={
            status === 'auth_failed' ||
            status === 'not_found' ||
            passwordStatus === 'invalid'
          }
          helperText={passwordErrorMessage()}
          onChange={e => handlePasswordChange(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {isHiddenPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <Button
          onClick={() => submitLoginForm()}
          className={styles.button}
          size="large"
          disabled={validateForm()}
        >
          <img alt={'bitley'} src={bitleyLogo} />
          bitkeyアカウントでログイン
        </Button>
        <p className={styles.passwordForget}>
          パスワードを忘れた方は
          <br />
          アプリから再設定してください。
        </p>
      </form>
    </AccountContainer>
  );
};
