import * as operations from './operations';
import {
  default as reducer,
  SignUpFormState as _SignUpFormState,
  initialState
} from './reducers';
import * as Selectors from './selectors';
export { operations, reducer, initialState, Selectors };
export type SignUpFormState = _SignUpFormState;

export default reducer;
