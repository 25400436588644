import React from 'react';
import BaseStepper from '../../components/BaseStepper';
import Confirm from '../../container/confirm';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';

interface Props {
  hasElectricContract?: boolean;
}

const ApplyConfirmScreen: React.FC<Props> = ({ hasElectricContract }) => {
  return (
    <>
      <ScrollToTop />
      <DesktopBackground>
        <BaseStepper step={2} hasElectoric={hasElectricContract} />
        <Confirm />
      </DesktopBackground>
    </>
  );
};

export default ApplyConfirmScreen;
const ScrollToTop = () => {
  function scroll() {
    window.scrollTo(0, 0);
  }
  window.setTimeout(function() {
    scroll();
  });
  return null;
};
