import React, { useCallback } from 'react';
import { CheckBoxOutlineBlank, CheckCircle } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  label: string;
  link: string;
};

export const TermsAgreementCheckbox = ({
  checked,
  onCheck,
  label,
  link
}: Props) => {
  const styles = useStyles();
  const onClickLink = useCallback(() => {
    onCheck(!checked);
  }, [onCheck, checked]);

  return (
    <div className={styles.root}>
      {checked ? (
        <CheckCircle color={'primary'} />
      ) : (
        <CheckBoxOutlineBlank color={'primary'} />
      )}
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        onClick={onClickLink}
      >
        {label}
      </a>
    </div>
  );
};

const useStyles = makeStyles(
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 14,
      marginBottom: 16,
      fontWeight: 'bold'
    }
  })
);
