import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import appReducer from './modules';
import { PropertyState } from './modules/property';
import { SignUpFormState } from './modules/signUp';
import { IndexState } from './modules/index/index';
import { SigninState } from './modules/signIn';
import { ApplyFormState } from './modules/application';
import { ValidationState } from './modules/validation';
import { UserState } from './modules/user';
import { IdentificationState } from './modules/identification';

export interface AppState {
  index: IndexState;
  property: PropertyState;
  signUpForm: SignUpFormState;
  signInForm: SigninState;
  applyForm: ApplyFormState;
  validation: ValidationState;
  user: UserState;
  identification: IdentificationState;
}

const storeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const createStoreWithMiddleware = compose(
  storeEnhancers(applyMiddleware(thunk))
)(createStore);

const store = createStoreWithMiddleware(appReducer);

export default store;
