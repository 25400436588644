import { LoginAction } from './actions';
import ActionType, { SigninErrorState, TextFieldStatus } from './types';

interface SigninFieldValue {
  value: string;
  status: TextFieldStatus;
}

type SigninStatus =
  | 'not_signin'
  | 'signin_loading'
  | 'signin'
  | SigninErrorState;

export interface SigninState {
  status: SigninStatus;
  identifyValue: SigninFieldValue;
  password: SigninFieldValue;
}

export const initialState: () => SigninState = () => {
  return {
    status: 'not_signin',
    identifyValue: {
      value: '',
      status: 'empty'
    },
    password: {
      value: '',
      status: 'empty'
    }
  };
};

const reducer = (
  state: SigninState = initialState(),
  action: LoginAction
): SigninState => {
  switch (action.type) {
    case ActionType.updateSigninIdentifyValue: {
      const newState = { ...state };
      newState.identifyValue.value = action.payload;
      newState.status = 'not_signin';
      newState.identifyValue.status = 'valid';
      return newState;
    }
    case ActionType.validateSigninIdentifyValue: {
      const newState = { ...state };
      newState.identifyValue.status = action.payload;
      return newState;
    }
    case ActionType.updateSigninPassword: {
      const newState = { ...state };
      newState.password.value = action.payload;
      newState.status = 'not_signin';
      newState.password.status = 'valid';
      return newState;
    }
    case ActionType.validateSigninPassword: {
      const newState = { ...state };
      newState.password.status = action.payload;
      return newState;
    }
    case ActionType.startToSignin: {
      const newState = { ...state };
      newState.status = 'signin_loading';
      return newState;
    }
    case ActionType.signinSuccess: {
      const newState = { ...state };
      newState.status = 'signin';
      return newState;
    }
    case ActionType.signinFailed: {
      const newState = { ...state };
      newState.status = action.payload;
      return newState;
    }
    case ActionType.clearSigninStatus: {
      return initialState();
    }
    default:
      ((_: never) => {
        return;
      })(action);
      return state;
  }
};

export default reducer;
