enum ActionType {
  updateParamater = 'UPDATE_PARAMATER',
  submitParamater = 'SUBMIT_PARAMATER',
  successToSubmitParamater = 'SUCCESS_TO_SUBMIT_PARAMATER',
  failedToSubmitParamater = 'FAILED_TO_SUBMIT_PARAMATER'
}

export type submitErrorStatus =
  | 'no_error'
  | 'conflict'
  | 'unauthorized'
  | 'internal';

export interface ApplyParamaterState {
  use_og_gas: string;
  family_name: string;
  first_name: string;
  family_name_kana: string;
  first_name_kana: string;
  phone_number: string;
  phone_number_when_move: string;
  phone_number_after_move: string;
  email: string;
  email_confirmation: string;
  birthday: string;
  pay_method: string | undefined;
  move_plan_date: string | undefined;
  start_power_date: string | undefined;
  start_gas_date: string | undefined;
  start_gas_time: string | undefined;
  isCoodinatedGasStartDate: boolean | undefined;
  gender: string | undefined;
  isSameEmail: boolean;
  isPhoneNumberError: boolean;
  isPhoneNumberWhenMoveError: boolean;
  isEmailMatch: boolean;
}

export default ActionType;
