import React, { useEffect } from 'react';
import { Container, Button } from '@material-ui/core';
import { Path } from '../../constants/path';
import { ApplyHeading, ApplyCaption } from '../../components/Label';
import { OccupancyLabelBox } from '../../components/OccupancyLabel';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Table, Tr } from '../../components/Table';
import { mapStateToProps, mapDispatchToProps } from '../../container/confirm';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import { Loading } from '../atoms/Loading';
import { BaseFab } from '../BaseFab';
import WarningIcon from '@material-ui/icons/Warning';
import { SumamoruProjectType } from "../../modules/property/types";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 0 16px',
      position: 'relative'
    },
    applyButton: {
      margin: '24px 0 0',
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-key)',
      width: '100%',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.16)'
    },
    backButtonContainer: {
      margin: '40px 0',
      textAlign: 'center'
    },
    backButton: {
      color: 'var(--color-gray-3)',
      textDecoration: 'none'
    },
    loadingContainer: {
      margin: '120px auto',
      width: 140,
      '@media (min-width:768px)': {
        height: '500px'
      }
    },
    loadingCaption: {
      color: 'var(--color-key)',
      marginTop: 20,
      fontSize: 16,
      textAlign: 'center'
    },
    errorMessage: {
      backgroundColor: '#E91E63',
      color: '#FFFFFF',
      padding: '1px 12px',
      borderRadius: 4,
      textAlign: 'left',
      margin: '16px 0'
    },
    errorTitle: {
      fontWeight: 'bold',
      fontSize: '18px',
      display: 'inline-flex'
    },
    errorDesc: {
      fontWeight: 'normal',
      fontSize: '14px',
      marginTop: '0'
    },
    editButton: {
      color: 'var(--color-key)',
      position: 'absolute',
      fontSize: 14,
      top: 4,
      right: 0
    },
    tokushohou: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: 14,
      color: 'var(--color-error)'
    },
    link: {
      textDecoration: 'none',
      color: 'var(--color-key)',
      fontWeight: 600
    }
  })
);

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const ApplyConfirmTemplate: React.FC<Props> = ({
  property,
  use_og_gas,
  start_power_date,
  start_gas_date,
  start_gas_time,
  first_name,
  family_name,
  first_name_kana,
  family_name_kana,
  phone_number,
  phone_number_when_move,
  move_plan_date,
  birthday,
  pay_method_text,
  status,
  submitParater,
  confirmEmail
}) => {
  const styles = useStyles({});
  const history = useHistory();

  useEffect(() => {
    if (status === 'submit') {
      history.push(Path.application.complete);
    } else if (status === 'conflict') {
      history.push(Path.application.complete, { isAlreadyRegistered: true });
    }
  }, [status, history]);

  moment.updateLocale('ja', {
    weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
  });
  const date = moment(property.occupyScheduledDate).format(
    'YYYY年MM月DD日(ddd)'
  );
  const startPowerDate = moment(new Date(start_power_date)).format(
    'YYYY年MM月DD日(ddd)'
  );
  const startGasDate =
    start_gas_date &&
    moment(new Date(start_gas_date)).format('YYYY年MM月DD日(ddd)');

  const breakedText = text => {
    return text.split('¥n').map((line, key) => (
      <span key={key}>
        {line}
        <br />
      </span>
    ));
  };

  const birthDay = moment(birthday).format('YYYY年MM月DD日');

  const isNotUseGas = property.sumamoruProjectType === SumamoruProjectType.OGExternal || property.sumamoruProjectType === SumamoruProjectType.OGInternalOnlyElectricity;

  return (
    <Container>
      {status !== 'submit_loading' && (
        <>
          <Container className={styles.container}>
            {status === 'unauthorized' && (
              <div className={styles.errorMessage}>
                <h3 className={styles.errorTitle}>
                  <WarningIcon
                    style={{ marginRight: '10px', marginTop: '2px' }}
                  />
                  再ログインしてください
                </h3>
              </div>
            )}
            {status === 'internal' && (
              <div className={styles.errorMessage}>
                <h3 className={styles.errorTitle}>
                  <WarningIcon
                    style={{ marginRight: '10px', marginTop: '2px' }}
                  />
                  申し込みに失敗しました。
                </h3>
                <p className={styles.errorDesc}>
                  もう一度時間を置いてお試しください。
                  <br />
                  なんども失敗する場合はサポートまでご連絡ください。
                </p>
              </div>
            )}
            {status === 'conflict' && (
              <p className={styles.errorMessage}>既に申し込み済みです</p>
            )}
            <ApplyHeading text={'電気(スマモル賃貸プラン)加入'} />
            {property.hasElectricContract === false && (
              <OccupancyLabelBox desc={'スマモル賃貸サービスのみ申込む'} />
            )}
            {property.hasElectricContract === true && use_og_gas === '1' && (
              <OccupancyLabelBox
                desc={'大阪ガスの電気(スマモル賃貸プラン)に申込む'}
              />
            )}
            {property.hasElectricContract === true && use_og_gas === '0' && (
              <OccupancyLabelBox desc={'スマモル賃貸プランに加入する'} />
            )}
          </Container>

          {!isNotUseGas && property.hasElectricContract === true && (
            <Container className={styles.container}>
              <ApplyHeading text={'都市ガスの開栓申し込み'} />
              {use_og_gas === '1' && (
                <OccupancyLabelBox desc={'ガスの開栓申し込みも一緒に行う'} />
              )}
              {use_og_gas === '0' && (
                <OccupancyLabelBox
                  desc={'ガスの開栓申し込みはしない/プロパンガス物件'}
                />
              )}
              <Link to={`${Path.application.url}#gas`}>
                <div className={styles.editButton}>変更</div>
              </Link>
            </Container>
          )}

          {property.hasElectricContract === true && (
            <Container className={styles.container}>
              <ApplyHeading text={'お引越し情報'} />
              <Table>
                <>
                  <Tr th={'入居予定日'} td={`${date}`} />
                  <Tr th={'電気:利用開始日'} td={startPowerDate} />
                  {!isNotUseGas && use_og_gas === '1' && (
                    <>
                      <Tr
                        th={breakedText('ガス:¥n開栓希望日・時間帯')}
                        td={
                          !startGasDate
                            ? '開栓希望日を大阪ガスに直接連絡する'
                            : `${startGasDate} ${start_gas_time}`
                        }
                      />
                      <Tr
                        th={breakedText('ガス開栓日に¥n連絡可能な電話番号')}
                        td={phone_number_when_move}
                      />
                    </>
                  )}
                </>
              </Table>
              <Link to={`${Path.application.url}#moving`}>
                <div className={styles.editButton}>変更</div>
              </Link>
            </Container>
          )}

          <Container className={styles.container}>
            <ApplyHeading text={'お客様情報'} />
            <Table>
              <>
                <Tr th={'氏名'} td={`${family_name} ${first_name}`} />
                <Tr
                  th={'氏名(カナ)'}
                  td={`${family_name_kana} ${first_name_kana}`}
                />
                <Tr th={'生年月日'} td={birthDay} />
                <Tr th={'電話番号'} td={phone_number} />
                <Tr th={'メールアドレス'} td={confirmEmail} />
              </>
            </Table>
            <Link to={`${Path.application.url}#userinfo`}>
              <div className={styles.editButton}>変更</div>
            </Link>
          </Container>

          {property.hasElectricContract === true && (
            <Container className={styles.container}>
              <ApplyHeading text={'お支払い手続き'} />
              <ApplyCaption
                text={'※お手続きが完了するまでは払込票でのお支払いとなります。'}
              />
              <OccupancyLabelBox desc={pay_method_text} />
              <Link to={`${Path.application.url}#paymethod`}>
                <div className={styles.editButton}>変更</div>
              </Link>
            </Container>
          )}
          <div className={styles.tokushohou}>
            <div>{'特商法第12条の6に基づく表示は'}</div>
            <a
              className={styles.link}
              href={
                property.sumamoruProjectType === SumamoruProjectType.OGExternal
                  ? '/static/specified_commercial_transactions_act_og_external.pdf'
                  : '/static/specified_commercial_transactions_act_og_internal.pdf'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {'こちら'}
            </a>
          </div>
          <BaseFab
            className={styles.applyButton}
            variant="extended"
            size="large"
            aria-label="add"
            onClick={() => submitParater()}
          >
            この内容で申し込む
          </BaseFab>

          <Link to={Path.application.url} style={{ textDecoration: 'none' }}>
            <div className={styles.backButtonContainer}>
              <Button className={styles.backButton}>戻る</Button>
            </div>
          </Link>
        </>
      )}
      {status === 'submit_loading' && (
        <div className={styles.loadingContainer}>
          <Loading />
          <p className={styles.loadingCaption}>申し込み中です...</p>
        </div>
      )}
    </Container>
  );
};

export default ApplyConfirmTemplate;
