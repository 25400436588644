import React from 'react';

interface P {
    body: string;
}
const fixText = (v) => {
    if (v.includes && v.includes('http')) return (<a href={v}>{v}</a>);
    return v;
}

/**
 * @returns 
 */
 const TextToTerm: React.FC<P> = (props) => {
    return (
        <>
            { props.body.trim().split('\n\n').map((block) => {
                const rows = block.trim().split('\n');
                const title = rows.shift();
                return (
                    <>
                        <h2>{title}</h2>
                        <p>
                            {rows.map( v => <>{fixText(v)}<br /></>)}
                        </p>
                    </>
                )}
            )}
        </>
    );
}

export default TextToTerm;