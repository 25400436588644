import * as operations from './operations';
import {
  default as reducer,
  IdentificationState as _IdentificationState,
  initialState
} from './reducers';
import * as Selectors from './selectors';
export { reducer, initialState, operations, Selectors };
export type IdentificationState = _IdentificationState;

export default reducer;
