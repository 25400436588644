import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { SumamoruPlan } from '../modules/property/types';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      background: '#ECEFF1',
      textAlign: 'center'
    },
    container: {
      margin: 0,
      padding: '10px 15px'
    },
    linkTitle: {
      fontSize: '12px',
      lineHeight: '17px',
      color: '#828282',
      margin: '0 12px'
    }
  })
);

type Props = {
  sumamoruPlan?: SumamoruPlan;
};

const Footer: React.FC<Props> = props => {
  const { sumamoruPlan } = props;
  const styles = useStyles({});
  return (
    <div className={styles.background}>
      <Container className={styles.container}>
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://www.osakagas.co.jp/info/policy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          サイトポリシー
        </a>
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://www.osakagas.co.jp/info/privacy.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          大阪ガスプライバシーポリシー
        </a>
        <br />
        {sumamoruPlan && (
          <a
            className={styles.linkTitle}
            style={{ textDecoration: 'none' }}
            href="https://home.osakagas.co.jp/support/internet/tokuteiho/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            特定商取引法に基づく表示
          </a>
        )}
        <a
          className={styles.linkTitle}
          style={{ textDecoration: 'none' }}
          href="https://bitkey.co.jp/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          bitkeyプライバシーポリシー
        </a>
      </Container>
    </div>
  );
};

export default Footer;
