import { withStyles } from '@material-ui/styles';
import { TextField } from '@material-ui/core';

export const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E0E0'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--color-key)'
      },
      '&.Mui-error fieldset': {
        background:
          'background: linear-gradient(0deg, rgba(233, 30, 99, 0.07), rgba(233, 30, 99, 0.07)), #FFFFFF',
        borderWidth: '2px',
        borderColor: 'var(--color-error)'
      },
      '&.Mui-error input': {
        background: '#fff'
      },
      '& input': {
        borderRadius: '4px',
        color: 'var(--color-gray-2)',
        fontSize: 20,
        letterSpacing: '0.05em',
        '&::placeholder': {
          fontSize: 17
        }
      }
    }
  }
})(TextField);
