import React, { useState, useEffect } from 'react';
import { Container, Button } from '@material-ui/core';
import BaseStepper from '../../components/BaseStepper';
import { ApplyHeading, AccountCaption } from '../../components/Label';
import { AccountContainer } from '../../components/AccountContainer';
import Validation from '../../container/validation';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Path } from '../../constants/path';
import { useHistory } from 'react-router-dom';
import {
  mapStateToProps,
  mapDispatchToProps
} from '../../container/validationScreen';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 24px',
      '@media (min-width:768px)': {
        padding: '0 32px 32px',
        '& h1': {
          textAlign: 'center'
        },
        '&>p': {
          textAlign: 'center',
          fontSize: 14
        }
      }
    },
    backButton: {
      color: 'var(--color-gray-3)',
      textDecoration: 'none',
      marginTop: '32px',
      fontSize: '16px'
    }
  })
);

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ValidationScreen: React.FC<Props> = ({
  hasElectricContract,
  backStatus,
  status
}) => {
  const history = useHistory();
  const styles = useStyles({});
  const [back, setBack] = useState(false);

  useEffect(() => {
    if (status === 'not_signup' && back) {
      history.push(Path.account.signup);
    }
  }, [back, status, history]);

  const handleClick = () => {
    backStatus();
    setBack(true);
  };

  return (
    <DesktopBackground>
      <BaseStepper step={0} hasElectoric={hasElectricContract} />
      <Container className={styles.container}>
        <ApplyHeading text={'Bitkeyアカウント連携'} />
        <AccountCaption
          text={
            'スマモル賃貸プランのご利用には、Bitkey社の提供するbitkeyアカウントが必要です。'
          }
        />
        <AccountContainer>
          <Validation />
        </AccountContainer>
        <div style={{ textAlign: 'center' }}>
          <Button className={styles.backButton} onClick={handleClick}>
            戻る
          </Button>
        </div>
      </Container>
    </DesktopBackground>
  );
};

export default ValidationScreen;
