import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import { AnyAction } from 'redux';
import AuthService, { AuthServiceError } from '../../utils/BkpAuthService';
import { AppState } from '../../store';
import { SignupMethod } from './types';

export const submitSignupForm = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  AnyAction
> => async (dispatch, getState) => {
  try {
    dispatch(Actions.startToSignup());

    const { email, phoneNumber, signupMethod } = getState().signUpForm;

    if (signupMethod === SignupMethod.PhoneNumber) {
      // 電話番号による登録
      AuthService.preRegisterUserWithPhoneNumber(phoneNumber.value)
        .then(data => {
          dispatch(Actions.signupSuccess(data));
        })
        .catch(error => {
          switch (error) {
            case AuthServiceError.BKP_FAILED_ALREADY_EXIST: {
              dispatch(Actions.signupFailed('already_exist'));
              return;
            }
            default: {
              dispatch(Actions.signupFailed('internal'));
              return;
            }
          }
        });
    } else {
      // メールアドレスによる登録
      AuthService.preRegisterUser(email.value)
        .then(data => {
          dispatch(Actions.signupSuccess(data));
        })
        .catch(error => {
          switch (error) {
            case AuthServiceError.BKP_FAILED_ALREADY_EXIST: {
              dispatch(Actions.signupFailed('already_exist'));
              return;
            }
            default: {
              dispatch(Actions.signupFailed('internal'));
              return;
            }
          }
        });
    }
  } catch (error) {
    dispatch(Actions.signupFailed('internal'));
  }
};
