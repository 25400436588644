import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

interface BoxProps {
  style?: React.CSSProperties;
  desc: string;
  address?: string;
}

interface Props extends BoxProps {
  label: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      textAlign: 'left',
      marginTop: 20
    },
    heading: {
      margin: 0,
      fontSize: 14,
      color: 'var(--color-gray-3)'
    },
    largeInfoBase: {
      backgroundColor: '#f2f2f2',
      padding: '16px 12px',
      borderRadius: 4,
      marginTop: 6
    },
    infoBase: {
      backgroundColor: '#f2f2f2',
      padding: '12px',
      borderRadius: 4,
      marginTop: 6
    },
    description: {
      margin: 0,
      fontSize: 14,
      lineHeight: '20px',
      color: 'var(--color-text)'
    },
    address: {
      margin: '6px 0 0',
      fontSize: 12,
      color: 'var(--color-gray-3)'
    }
  })
);

export const OccupancyLabelBox = (props: BoxProps) => {
  const styles = useStyles({});
  const infoBaseClass = props.address ? styles.largeInfoBase : styles.infoBase;

  return (
    <div className={infoBaseClass}>
      <p className={styles.description}>{props.desc}</p>
      {props.address && <p className={styles.address}>{props.address}</p>}
    </div>
  );
};

const OccupancyLabel = (props: Props) => {
  const styles = useStyles({});

  return (
    <div className={styles.container} style={props.style}>
      <h2 className={styles.heading}>{props.label}</h2>
      <OccupancyLabelBox desc={props.desc} address={props.address} />
    </div>
  );
};

export default OccupancyLabel;
