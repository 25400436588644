import { connect } from 'react-redux';
import { AppState } from '../store';
import InitialTemplate from '../components/template/InitialTemplate';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as Actions from '../modules/index/actions';
import * as module from '../modules/index/index';
import * as propertyModule from '../modules/property';
import * as propertyActions from '../modules/property/actions';

export const mapStateToProps = (state: AppState) => {
  return {
    property: state.property.property,
    propertyStatus: state.property.status,
    status: state.index.status
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => ({
  setQueryParams: (allSpaceID: string, contractId: string) => {
    dispatch(propertyActions.setAllSpaceId(allSpaceID));
    dispatch(propertyActions.setContractId(contractId));
    dispatch(Actions.setQueryParams());
  },
  invalidQueryParams: () => dispatch(Actions.invalidQueryParams()),
  getProperty: () => dispatch(propertyModule.operations.getProperty()),
  saveQueryToCookie: () => dispatch(module.operations.saveQueryParamsToCookie())
});

export default connect(mapStateToProps, mapDispatchToProps)(InitialTemplate);
