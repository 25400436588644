export const Path = {
  home: '/',
  redirect: '/redirect/',
  identification: '/identification',
  account: {
    signup: '/signup',
    signin: '/signin',
    validation: '/validation',
    completed: '/account/completed'
  },
  application: {
    url: '/apply',
    confirm: '/apply/confirm',
    complete: '/apply/complete'
  },
  other: {
    terms: '/terms',
    policy: '/policy',
    sitePolicy: '/site-policy',
    tokushoho: '/commercial-transactions',
    tokushoho_coupon: '/commercial-transactions/coupon',
    tokushoho_wlock: '/commercial-transactions/wlock',
    tokushoho_manga: '/commercial-transactions/manga',
    explanationMatter: '/explanation-matters',
    couponMatter: '/explanation-matters/coupon',
    wLockMatter: '/explanation-matters/wlock',
    mangaMatter: '/explanation-matters/manga',
    bo: '/terms/coupon',
    viewn: '/terms/manga',
    oss: '/terms/oss'
  }
};
