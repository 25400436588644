import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { Path } from '../../constants/path';
import BaseStepper from '../../components/BaseStepper';
import valdatedMark from '../../static/images/validatedMark.png';
import { ApplyHeading, AccountCaption } from '../../components/Label';
import { CustomKeyButton } from '../../components/CustomButton';
import { DesktopBackground } from '../../components/atoms/DesktopBackground';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '0 24px',
      '@media (min-width:768px)': {
        padding: '0 32px 32px',
        '& h1': {
          textAlign: 'center'
        },
        '&>p': {
          textAlign: 'center',
          fontSize: 14
        }
      }
    }
  })
);

interface Props {
  hasElectricContract?: boolean;
}

const CompletedScreen: React.FC<Props> = ({ hasElectricContract }) => {
  const styles = useStyles({});
  return (
    <DesktopBackground>
      <BaseStepper step={0} hasElectoric={hasElectricContract} />
      <Container className={styles.container}>
        <ApplyHeading text={'Bitkeyアカウント連携'} />
        <AccountCaption
          text={
            'スマモル賃貸プランのご利用には、Bitkey社の提供するbitkeyアカウントが必要です。'
          }
        />
        <div
          style={{
            textAlign: 'center',
            background: '#ECEFF1',
            padding: '70px 0 50px',
            borderRadius: 14,
            marginTop: 20
          }}
        >
          <img
            alt={'アカウントの連携完了'}
            width={'100px'}
            src={valdatedMark}
          />
          <p
            style={{
              color: '#03A9F4',
              fontSize: 16,
              fontWeight: 500,
              marginTop: 20
            }}
          >
            Bitkeyアカウントとの
            <br />
            連携が完了しました。
          </p>
        </div>
        <Link style={{ textDecoration: 'none' }} to={Path.application.url}>
          <CustomKeyButton
            style={{ marginTop: 40 }}
            variant="extended"
            size="large"
          >
            申込情報入力へ
          </CustomKeyButton>
        </Link>
      </Container>
    </DesktopBackground>
  );
};

export default CompletedScreen;
