import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, IconButton, InputAdornment } from '@material-ui/core';
import { AccountContainer } from './AccountContainer';
import { AccountFormHeading } from './Label';
import { CustomTextField } from './inputs/CustomTextField';
import bitkeyLogo from '../static/images/bitkeyLogo.png';
import { mapDispatchToProps, mapStateToProps } from '../container/signUpForm';
import { useHistory } from 'react-router';
import { Path } from '../constants/path';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Link from '@material-ui/core/Link';
import { SignupMethod } from '../modules/signUp/types';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(() =>
  createStyles({
    formContainer: {
      '@media (min-width:768px)': {
        width: 295,
        margin: '0 auto'
      }
    },
    textField: {
      margin: '16px 0 0',
      width: '100%',
      '& input': {
        background: 'white',
        padding: '16px 14px',
        fontSize: '14px !important',
        '&::placeholder': {
          fontSize: '14px !important'
        }
      },
      '& p': {
        fontWeight: 'bold',
        color: 'var(--color-error) !important',
        margin: '8px 0 0 !important'
      },
      '& .MuiOutlinedInput-adornedEnd': {
        backgroundColor: 'var(--color-white)',
        paddingRight: '4px'
      }
    },
    caption: {
      color: 'var(--color-text)',
      fontSize: 12,
      lineHeight: '17px',
      fontStyle: 'normal',
      fontWeight: 'normal'
    },
    captionLink: {
      color: 'var(--color-key)',
      textDecoration: 'none'
    },
    changeSignupMethodArea: {
      width: '100%',
      textAlign: 'end'
    },
    changeSignupMethodLink: {
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: 'var(--color-key)',
      cursor: 'pointer',
      textDecoration: 'none'
    },
    button: {
      margin: '16px 0 0',
      width: '100%',
      height: 32,
      padding: '0',
      fontSize: 12,
      lineHeight: '32px',
      color: 'white',
      textTransform: 'none',
      background: 'var(--color-bitkey)',
      '&.MuiButton-root:hover': {
        backgroundColor: 'var(--color-bitkey)'
      },
      '& img': {
        width: 14,
        marginRight: 8
      },
      '&:disabled': {
        backgroundColor: '#BDBDBD',
        color: 'var(--color-white)'
      }
    },
    passwordResetLink: {
      fontSize: 12,
      color: 'var(--color-bitkey)',
      textAlign: 'center',
      margin: '16px 0 0',
      textDecoration: 'none',
      display: 'block'
    },
    passwordCaption: {
      margin: '2px 0px 12px',
      fontSize: 12,
      color: 'var(--color-gray-3)'
    }
  })
);

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export const SignUpForm: FC<Props> = ({
  emailStatus,
  phoneNumberStatus,
  passwordStatus,
  status,
  updateEmail,
  updatePhoneNumber,
  updatePassword,
  submitSignup,
  property,
  getIds,
  getProperty,
  signupMethod,
  changeSignupMethod
}) => {
  const styles = useStyles({});
  const history = useHistory();

  const [fieldIdentifyValue, setFieldIdentifyValue] = useState('');
  const [fieldPassword, setFieldPassword] = useState('');
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);

  useEffect(() => {
    if (status === 'signup') {
      history.push(Path.account.validation);
    }
  }, [status, history]);

  useEffect(() => {
    if (property.allSpaceId === '') {
      getIds();
    }

    if (property.allSpaceId !== '' && property.address === '') {
      getProperty();
    }
  }, [property, getIds, getProperty]);

  const handleIdentifyValueChange = useCallback(
    (e: string) => {
      setFieldIdentifyValue(e);
      if (signupMethod === SignupMethod.Email) {
        updateEmail(e);
      } else {
        updatePhoneNumber(e);
      }
    },
    [signupMethod, updateEmail, updatePhoneNumber]
  );

  const emailErrorMessage = () => {
    if (emailStatus === 'invalid') {
      return '有効なメールアドレスを入力してください';
    } else if (status === 'already_exist') {
      return 'このメールアドレスは既に使われています';
    }
    return '';
  };

  const phoneNumberErrorMessage = () => {
    if (phoneNumberStatus === 'invalid') {
      return '有効な電話番号を入力してください';
    } else if (status === 'already_exist') {
      return 'この電話番号は既に使われています';
    }
    return '';
  };

  const handlePasswordChange = (p: string) => {
    setFieldPassword(p);
    updatePassword(p);
  };

  const passwordErrorMessage = () => {
    if (passwordStatus === 'invalid') {
      return '8〜56文字で有効なパスワード入力してください';
    }
    return '';
  };

  const handleClickShowPassword = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  const handleMouseDownPassword = () => {
    setIsHiddenPassword(!isHiddenPassword);
  };

  const handleClickChangeSignupMethod = () => {
    if (signupMethod === SignupMethod.Email) {
      changeSignupMethod(SignupMethod.PhoneNumber);
    } else {
      changeSignupMethod(SignupMethod.Email);
    }
  };

  const enableSubmit = useMemo(() => {
    const bkpAccountKeyStatus =
      signupMethod === SignupMethod.Email ? emailStatus : phoneNumberStatus;
    return passwordStatus === 'valid' && bkpAccountKeyStatus === 'valid';
  }, [passwordStatus, signupMethod, phoneNumberStatus, emailStatus]);

  return (
    <AccountContainer>
      <form className={styles.formContainer}>
        <AccountFormHeading text={'Bitkeyアカウントを作成'} />
        {signupMethod === SignupMethod.Email ? (
          <CustomTextField
            className={styles.textField}
            placeholder="メールアドレス"
            margin="normal"
            autoComplete="email"
            value={fieldIdentifyValue}
            style={{ marginTop: 24 }}
            error={status === 'already_exist' || emailStatus === 'invalid'}
            helperText={emailErrorMessage()}
            onChange={e => handleIdentifyValueChange(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
          />
        ) : (
          <Tooltip
            title={
              'ショートメッセージを受信可能な携帯電話番号を入力してください'
            }
            placement={'top-end'}
          >
            <CustomTextField
              className={styles.textField}
              placeholder="電話番号"
              margin="normal"
              autoComplete="tel"
              value={fieldIdentifyValue}
              style={{ marginTop: 24 }}
              error={
                status === 'already_exist' || phoneNumberStatus === 'invalid'
              }
              helperText={phoneNumberErrorMessage()}
              onChange={e => handleIdentifyValueChange(e.target.value)}
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
            />
          </Tooltip>
        )}
        <div className={styles.changeSignupMethodArea}>
          <Link
            className={styles.changeSignupMethodLink}
            onClick={handleClickChangeSignupMethod}
          >
            {signupMethod === SignupMethod.Email
              ? '電話番号で作成する'
              : 'メールアドレスで作成する'}
          </Link>
        </div>
        <CustomTextField
          className={styles.textField}
          placeholder="パスワード"
          type={isHiddenPassword ? 'password' : 'text'}
          margin="normal"
          autoComplete="new-password"
          value={fieldPassword}
          error={passwordStatus === 'invalid'}
          helperText={passwordErrorMessage()}
          onChange={e => handlePasswordChange(e.target.value)}
          InputLabelProps={{
            shrink: true
          }}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {isHiddenPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />
        <p className={styles.passwordCaption}>8〜56文字で設定</p>
        <span className={styles.caption}>
          <a
            href={'https://homehub.site/term/usage-term/'}
            className={styles.captionLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            利用規約
          </a>
          と
          <a
            href={'https://bitkey.co.jp/privacy-policy/'}
            target={'_blank'}
            className={styles.captionLink}
            rel="noreferrer noopener"
          >
            プライバシーポリシー
          </a>
          に同意の上、登録してください。
        </span>
        <Button
          disabled={!enableSubmit}
          className={styles.button}
          size="large"
          onClick={submitSignup}
        >
          <img alt={'bitley'} src={bitkeyLogo} />
          bitkeyアカウントを作成
        </Button>
      </form>
    </AccountContainer>
  );
};
