import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import { AnyAction } from 'redux';
import { AppState } from '../../store';
import moment from 'moment';
import { bitlockApiPath, ifBaseApiPath } from '../../config/baseConfig';
import { SumamoruInstallAppType } from '../property/types';

export const submitParamater = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  AnyAction
> => async (dispatch, getState) => {
  try {
    dispatch(Actions.submitParamater());
    const email = getState().user.email;
    const phoneNumber = getState().user.phoneNumber;
    const access_token = getState().user.access_token;
    const refresh_token = getState().user.refresh_token;
    const user_id = getState().user.user_id;
    const propParam = getState().property.property;
    const method = 'POST';
    const bitlockManageEndpoint = `${bitlockApiPath}/users`;
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': access_token
    };
    let body = JSON.stringify({
      bkp_user_id: user_id,
      email: email,
      phone_number: phoneNumber,
      // NOTE: 20240814, HRS-7782の対応
      // nameを設定しないとアカウント名がメールアドレスになるため、
      // 初回登録時は「アカウント名未設定」としておく
      name: 'アカウント名未設定',
    });

    let status = 0;

    const bitlockRes = await fetch(bitlockManageEndpoint, {
      method,
      headers,
      body,
      mode: 'cors'
    }).then(res => {
      if (res.status === 200 || res.status === 201) {
        // NOTE: 成功した場合はこの中では何もしない。
      } else if (res.status === 409) {
        // NOTE: 409の場合は、処理しない。
        // アカウントがすでに作成されている場合は、申込みに進ませる。
      } else if (res.status === 401) {
        dispatch(Actions.failedToSubmitParamater('unauthorized'));
        return { statusCode: res.status };
      } else {
        dispatch(Actions.failedToSubmitParamater('internal'));
        return { statusCode: res.status };
      }
      status = res.status;
    });

    if (bitlockRes !== undefined && bitlockRes.statusCode === 401) return;
    // 409は既にアカウントがある状態なので申請に進んで良い
    if (status !== 200 && status !== 409 && status !== 201) {
      dispatch(Actions.failedToSubmitParamater('internal'));
      return;
    }
    const formParam = getState().applyForm.parameters;
    const endpoint = `${ifBaseApiPath}/application/create`;

    const { isSameEmail } = getState().applyForm.parameters;
    const { isCoodinatedGasStartDate } = getState().applyForm.parameters;

    let form_email = formParam.email;
    let form_email_confirm = formParam.email_confirmation;

    if (isSameEmail) {
      form_email = email;
      form_email_confirm = email;
    }

    body = JSON.stringify({
      application_category: 0, // 新規固定
      property_id: propParam.allSpaceId,
      contract_id: propParam.contractId,
      property_no: propParam.sumamoruCode,
      sumamoru_plan: propParam.sumamoruPlan,
      address1: propParam.prefecture + propParam.city + propParam.afterCity,
      address2: propParam.buildingName + propParam.name,
      sex: parseInt(formParam.gender),
      postal_code: propParam.postCode,
      use_og_gas: parseInt(formParam.use_og_gas),
      has_electric_contract: propParam.hasElectricContract,
      family_name: formParam.family_name,
      first_name: formParam.first_name,
      family_name_kana: formParam.family_name_kana,
      first_name_kana: formParam.first_name_kana,
      phone_number: formParam.phone_number,
      phone_number_when_move: formParam.phone_number_when_move,
      phone_number_after_move: formParam.phone_number,
      email: form_email,
      email_confirmation: form_email_confirm,
      birthday: formParam.birthday,
      pay_method: parseInt(formParam.pay_method),
      move_plan_date: moment(propParam.occupyScheduledDate).format(
        'YYYY-MM-DD'
      ),
      start_power_date: getDateString(formParam.start_power_date),
      start_gas_date: isCoodinatedGasStartDate
        ? undefined
        : getDateString(formParam.start_gas_date),
      start_gas_time: isCoodinatedGasStartDate
        ? undefined
        : formParam.start_gas_time,
      code: propParam.code,
      prefecture: propParam.prefecture,
      city: propParam.city,
      afterCity: propParam.afterCity,
      building_name: propParam.buildingName,
      name: propParam.name,
      refresh_token: refresh_token,
      organization_id: getOrganizationId(propParam.allSpaceId),
      sumamoru_project_type: propParam.sumamoruProjectType,
      install_app_name:
        propParam.sumamoruInstallAppTypes &&
        propParam.sumamoruInstallAppTypes.some(
          type => type === SumamoruInstallAppType.homehub
        )
          ? SumamoruInstallAppType.homehub
          : SumamoruInstallAppType.bitlock
    });

    fetch(endpoint, {
      method,
      headers,
      body,
      mode: 'cors'
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(Actions.successToSubmitParamater());
        } else if (res.status === 409) {
          dispatch(Actions.failedToSubmitParamater('conflict'));
        } else {
          dispatch(Actions.failedToSubmitParamater('internal'));
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(Actions.failedToSubmitParamater('internal'));
      });
  } catch (error) {
    console.error(error);
    dispatch(Actions.failedToSubmitParamater('internal'));
  }
};

const getDateString = (date?: string): string | undefined => {
  if (!date || date.length === 0) {
    return undefined;
  }
  return moment(new Date(date)).format('YYYY-MM-DD');
};

const getOrganizationId = (allSpaceId: string): string | undefined => {
  if (!allSpaceId || allSpaceId.length === 0) {
    return undefined;
  }
  const splited = allSpaceId.split('_');
  if (!splited || splited.length === 0) {
    return undefined;
  }
  return splited[0];
};
