import React from 'react';

const SmartLockTerm: React.FC = () => {
    return (
        <>
        <h1>＜スマートロックの解錠サポートに関する規程＞</h1>
        <h2>第２４条（サービス内容）</h2>
        <br />
        当社は、お客さまに対して、スマートロックの解錠サポートを行います。当社は、本業務をOSS等に委託します。
        <br />
        ２．対象となるスマートロックは、株式会社ビットキーが提供するスマートロック（お客さまの需要場所の入口等の鍵に取り付けることで，スマートフォンや専用のリモコンキーから扉の鍵を開閉できる商品等であって、当社が指定するもの）に限ります。
        <br />
        ３．お客さまの依頼をいただいた場合、警備員は事前にお客さまよりお預けいただいたワンタイムパスワードを用いて解錠を行います。お客さまが事前にワンタイムパスワードをお預けにならない場合、解錠を行うことはできません。
        <br />
        <h2>第２５条（スマートロックの解錠サポートの料金）</h2>
        <br />
        本サービスの提供期間中のスマートロックの開錠サポートについては、年間1回までは無償とさせていただきます。年間2回目以降の、警備員かけつけに対しては、出動に要する費用（１回の出動あたり５，５００円（税込））をお客さまに従量サービス料金として請求します。なお、入居日から起算して1年を「年間」とし、従量サービス料金の要否を判断するための年間の出動回数については、第１９条の警備員かけつけのための警備員の出動と合算してカウントするものとします。
        <br />
        ２．従量サービス料金は、出動の指示を行った時点で発生するものとし、お客さまが出動をキャンセル・変更された場合でもお支払いいただきます。
        <br />
        <h2>第２６条（免責）</h2>
        <br />
        スマートロックの解錠サポートに関して、以下の事項に該当する場合、本サービスの提供をお断りする場合があります。
        <br />
        ①お客さま以外の依頼
        <br />
        ②災害・天災・暴動等に起因する依頼
        <br />
        ③カギの開錠の場合にお客さま本人の立会いがない場合
        <br />
        ④運転免許証等の顔写真付公的身分証明書の提示がない場合。また、顔写真付公的身分証明書の提示があっても、身分証明書上の住所、氏名が当社に届出のある住所、氏名と異なっていた場合
        <br />
        ⑤スマートロックの解錠サポートとは異なるサポート依頼
        <br />
        ⑥対象物件以外に関する本サービスの依頼
        <br />
        ⑦離島もしくはサービス提供が困難な地域からの依頼
        <br />
        ２．天候・交通状況・作業員の作業状況等により現場到着に相応の時間を要する場合があります。
        <br />
        ３．当社及び業務委託先（業務委託先の業務提携先を含む）は、故意または過失がない限り、スマートロックの解錠サポートに関して、損害賠償等の責任を負わないものとします。
        <br />
        ４．ワンタイムパスワードによる解錠が困難な場合等、OSS等の当社からの委託先では対応できない場合は、OSS等は専門業者等を手配することができます。ただし、当該専門業者による対応に要する費用はお客さまのご負担となる場合があります。
        </>
    );
}

export default SmartLockTerm;