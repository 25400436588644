import { connect } from 'react-redux';
import { SignUpForm } from '../components/SignUpForm';
import * as Actions from '../modules/signUp/actions';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import { submitSignupForm } from '../modules/signUp/operations';
import { SignupMethod } from '../modules/signUp/types';
import * as module from '../modules/signUp';
import * as propertyModule from '../modules/property';

export const mapStateToProps = (state: AppState) => {
  return {
    emailStatus: module.Selectors.getEmailStatus(state),
    phoneNumberStatus: module.Selectors.getPhoneNumberStatus(state),
    password: module.Selectors.getPassword(state),
    passwordStatus: module.Selectors.getPasswordStatus(state),
    status: module.Selectors.getStatus(state),
    property: propertyModule.Selectors.getProperty(state),
    signupMethod: module.Selectors.getSignupMethod(state)
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => ({
  updateEmail: (value: string) => dispatch(Actions.updateEmail(value)),
  updatePhoneNumber: (value: string) =>
    dispatch(Actions.updatePhoneNumber(value)),
  updatePassword: (value: string) => dispatch(Actions.updatePassword(value)),
  submitSignup: () => dispatch(submitSignupForm()),
  getIds: () => dispatch(propertyModule.operations.setIdsFromCookie()),
  getProperty: () => dispatch(propertyModule.operations.getProperty()),
  changeSignupMethod: (value: SignupMethod) =>
    dispatch(Actions.changeSignupMethod(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
