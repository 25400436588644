import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  SumamoruInstallAppType,
  SumamoruPlan,
  SumamoruProjectType
} from '../../modules/property/types';

const useStyles = makeStyles(() => ({
  caption: {
    fontSize: 12,
    lineHeight: '17px',
    color: 'var(--color-gray-3)',
    textAlign: 'left',
    '& span': {
      color: 'var(--color-sub)'
    }
  },
  linkContainer: {
    margin: '24px 24px 0'
  },
  link: {
    textDecoration: 'none',
    color: 'var(--color-key)',
    fontWeight: 600
  }
}));

type Props = {
  sumamoruPlan?: SumamoruPlan;
  sumamoruInstallAppTypes: Array<string>;
  sumamoruProjectType: SumamoruProjectType | undefined;
};

const PDF_LINKS = {
  DEFAULT_BITLOCK:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru.pdf',
  DEFAULT_HOMEHUB:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru.pdf',
  OG_VIEWN:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru_book.pdf',
  OG_W_LOCK:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru_lock.pdf'
};

const OG_EXTERNAL = {
  DEFAULT_BITLOCK:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru_other.pdf',
  DEFAULT_HOMEHUB:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru.pdf',
  OG_VIEWN:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru_book.pdf',
  OG_W_LOCK:
    'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/sumamoru_lock.pdf'
};

const PdfLink: React.FC<Props> = ({
  sumamoruPlan,
  sumamoruInstallAppTypes,
  sumamoruProjectType
}) => {
  const classes = useStyles();
  let href;
  if (sumamoruPlan === SumamoruPlan.OG_WLock) {
    sumamoruProjectType === SumamoruProjectType.OGExternal
      ? (href = OG_EXTERNAL.OG_W_LOCK)
      : (href = PDF_LINKS.OG_W_LOCK);
  } else if (sumamoruPlan === SumamoruPlan.OG_Viewn) {
    sumamoruProjectType === SumamoruProjectType.OGExternal
      ? (href = OG_EXTERNAL.OG_VIEWN)
      : (href = PDF_LINKS.OG_VIEWN);
  } else {
    if (sumamoruInstallAppTypes.includes(SumamoruInstallAppType.homehub)) {
      sumamoruProjectType === SumamoruProjectType.OGExternal
        ? (href = OG_EXTERNAL.DEFAULT_HOMEHUB)
        : (href = PDF_LINKS.DEFAULT_HOMEHUB);
    } else {
      sumamoruProjectType === SumamoruProjectType.OGExternal
        ? (href = OG_EXTERNAL.DEFAULT_BITLOCK)
        : (href = PDF_LINKS.DEFAULT_BITLOCK);
    }
  }

  return (
    <>
      <div className={classes.linkContainer}>
        <p className={classes.caption}>
          別途、郵送されるパンフレットでご説明している電気プラン、サービスは
          <a
            className={classes.link}
            href={href}
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
          からご確認いただけます。
        </p>
      </div>
    </>
  );
};

export default PdfLink;
