import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../store';
import { AnyAction } from 'redux';
import * as propertyModule from '../modules/property';
import * as module from '../modules/application';
import ApplyConfirmTemplate from '../components/template/ConfirmTemplate';

export const mapStateToProps = (state: AppState) => {
  return {
    property: propertyModule.Selectors.getProperty(state),
    use_og_gas: module.Selectors.getUseOgGas(state),
    start_power_date: module.Selectors.getStartPowerDate(state),
    start_gas_date: module.Selectors.getGasDate(state),
    start_gas_time: module.Selectors.getGasTime(state),
    first_name: module.Selectors.getFirstName(state),
    family_name: module.Selectors.getFamilyName(state),
    first_name_kana: module.Selectors.getFirstNameKana(state),
    family_name_kana: module.Selectors.getFamilyNameKana(state),
    phone_number: module.Selectors.getPhoneNumber(state),
    phone_number_when_move: module.Selectors.getPhoneNumberWhenMove(state),
    move_plan_date: module.Selectors.getMovePlanDate(state),
    birthday: module.Selectors.getBirthday(state),
    pay_method_text: module.Selectors.getPayMethodText(state),
    status: state.applyForm.status,
    confirmEmail: module.Selectors.getConfirmEmail(state)
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => ({
  submitParater: () => dispatch(module.operations.submitParamater())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplyConfirmTemplate);
