import ActionType, { submitErrorStatus } from './types';
import { ActionWithPayload } from '../utils/types';

export interface updateParamaterPayload {
  key: string;
  value: number | string | boolean | undefined;
}

export const updateParamater = (
  key: string,
  value: number | string | boolean | undefined
): ActionWithPayload<ActionType.updateParamater, updateParamaterPayload> => ({
  type: ActionType.updateParamater,
  payload: { key, value }
});

export const submitParamater = (): ActionWithPayload<
  ActionType.submitParamater,
  {}
> => ({
  type: ActionType.submitParamater,
  payload: {}
});

export const successToSubmitParamater = (): ActionWithPayload<
  ActionType.successToSubmitParamater,
  {}
> => ({
  type: ActionType.successToSubmitParamater,
  payload: {}
});

export const failedToSubmitParamater = (
  error: submitErrorStatus
): ActionWithPayload<
  ActionType.failedToSubmitParamater,
  submitErrorStatus
> => ({
  type: ActionType.failedToSubmitParamater,
  payload: error
});

export type ApplicatonAction =
  | ReturnType<typeof updateParamater>
  | ReturnType<typeof submitParamater>
  | ReturnType<typeof successToSubmitParamater>
  | ReturnType<typeof failedToSubmitParamater>;
