import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../store';
import { AnyAction } from 'redux';
import * as Actions from './actions';
import * as PropertyActions from '../property/actions';
import * as UserActions from '../user/actions';
import {
  bitlockVoApiPath,
  bitlockVoVerifyIdentityApiKey
} from '../../config/baseConfig';
import { PrefectureJPMap } from '../../utils/address-types';
import { Property } from '../property/types';

export const verifyIdentification = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  AnyAction
> => async (dispatch, getState) => {
  try {
    dispatch(Actions.verifyIdentification());
    const { allSpaceId, contractId } = getState().property.property;
    const formParam = getState().identification.parameters;
    const birthday = formParam.birthday;
    // bitlock-service側ではYYYY-MM-DDの形なので整形する
    const formattedBirthday =
      birthday.slice(0, 4) +
      '-' +
      birthday.slice(4, 6) +
      '-' +
      birthday.slice(6, 8);

    const endpoint = `${bitlockVoApiPath}/spaces/${allSpaceId}/contracts/${contractId}/verify_identity`;
    const apiKey = bitlockVoVerifyIdentityApiKey;
    const headers = {
      'x-vo-identify-api-key': apiKey,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    const method = 'PUT';

    const body = JSON.stringify({
      birthday: formattedBirthday
    });
    let propertyData = {} as Property;
    await fetch(endpoint, {
      method,
      headers,
      body,
      mode: 'cors'
    })
      .then(res => {
        if (res.status !== 200) {
          // 失敗の場合の処理
          if (res.status === 403) {
            console.log('already locked out this contract.', res);
            dispatch(Actions.failedToVerifyIdentification('locked'));
          } else {
            console.log('failed to verify identity.', res);
            dispatch(Actions.failedToVerifyIdentification('unauthorized'));
          }
          return;
        }
        res.json().then(json => {
          // 成功の場合の処理
          console.log('verify identity is succeed.', json);
          const spaceWithContract = json.spaceWithContract;
          propertyData = {
            allSpaceId: allSpaceId,
            contractId: contractId,
            applicationStatus: spaceWithContract.applicationStatus,
            hasElectricContract: spaceWithContract.hasElectricContract,
            occupyScheduledDate: new Date(
              spaceWithContract.occupyScheduledDate
            ),
            postCode: spaceWithContract.postCode,
            address: spaceWithContract.address,
            prefecture: PrefectureJPMap[spaceWithContract.prefecture],
            city: spaceWithContract.city,
            afterCity: spaceWithContract.afterCity,
            buildingName: spaceWithContract.buildingName,
            name: spaceWithContract.name,
            code: spaceWithContract.code,
            sumamoruCode: spaceWithContract.sumamoruCode,
            sumamoruPlan: spaceWithContract.sumamoruPlan,
            status: spaceWithContract.status,
            managementCompanyAddress:
              spaceWithContract.managementCompanyAddress,
            managementCompanyName: spaceWithContract.managementCompanyName,
            managementCompanyPhoneNumber:
              spaceWithContract.managementCompanyPhoneNumber,
            sumamoruInstallAppTypes: spaceWithContract.sumamoruInstallAppTypes,
            sumamoruProjectType: spaceWithContract.sumamoruProjectType
          };
          dispatch(UserActions.setBirthday(birthday));
          dispatch(PropertyActions.setProperty(propertyData));
          dispatch(Actions.successToVerifyIdentification());
        });
      })
      .catch(error => {
        console.error(error);
        dispatch(Actions.failedToVerifyIdentification('internal'));
      });
  } catch (error) {
    // ここにエラー処理
    console.error(error);
    dispatch(Actions.failedToVerifyIdentification('internal'));
  }
};
