import { ActionWithPayload } from '../utils/types';
import ActionType, { verifyIdentificationErrorStatus } from './types';

export interface UpdateParameterPayload {
  key: string;
  value: number | string | boolean | undefined;
}

export const updateParameter = (
  key: string,
  value: number | string | boolean | undefined
): ActionWithPayload<ActionType, UpdateParameterPayload> => ({
  type: ActionType.updateParameter,
  payload: { key, value }
});

export const verifyIdentification = (): ActionWithPayload<
  ActionType.verifyIdentification,
  {}
> => ({
  type: ActionType.verifyIdentification,
  payload: {}
});

export const successToVerifyIdentification = (): ActionWithPayload<
  ActionType.successToVerifyIdentification,
  {}
> => ({
  type: ActionType.successToVerifyIdentification,
  payload: {}
});

export const failedToVerifyIdentification = (
  error: verifyIdentificationErrorStatus
): ActionWithPayload<
  ActionType.failedToVerifyIdentification,
  verifyIdentificationErrorStatus
> => ({
  type: ActionType.failedToVerifyIdentification,
  payload: error
});

export type IdentificationAction =
  | ReturnType<typeof updateParameter>
  | ReturnType<typeof verifyIdentification>
  | ReturnType<typeof successToVerifyIdentification>
  | ReturnType<typeof failedToVerifyIdentification>;
