import { emailRegex, passwordRegexp, phoneRegex } from '../constants/regex';

export default class StringUtils {
  static isEmail(email: string): boolean {
    return emailRegex.test(email);
  }

  static isValidPhoneNumber = (phoneNumber: string): boolean =>
    phoneRegex.test(phoneNumber);

  /**
   * 有効なパスワードを表現する文字列か否かをチェックする
   * @param str
   */
  static isValidPassword = (str: string): boolean => passwordRegexp.test(str);
}
