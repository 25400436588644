import * as React from 'react';
import { useHistory } from 'react-router';
import { useEffect } from 'react';
import { Path } from '../constants/path';

const RedirectWithDecodeURL: React.FC<{}> = () => {
  const history = useHistory();
  // 本人確認画面へのRedirect処理を行う
  useEffect(() => {
    const decodePathName = decodeURIComponent(history.location.pathname);
    const searchParams = decodePathName.slice(
      decodePathName.indexOf(Path.redirect) + Path.redirect.length
    );
    history.replace({
      pathname: Path.identification,
      search: searchParams
    });
  }, []);
  return <div />;
};

export default RedirectWithDecodeURL;
