import React from 'react';

const ViewnTerm: React.FunctionComponent = () => {
    return (
        <>
            <h1>ビューン読み放題マンション等利用規約</h1>
            <p>「ビューン読み放題マンション等利用規約」（以下「本規約」といいます。）は、株式会社ビューン（以下「当社」といいます。）が提供する、デジタル化された雑誌、マンガ、書籍等の電子コンテンツの閲覧サービス「ビューン読み放題マンション」及び「ビューン読み放題」（以下、両サービスを総称して「本サービス」といいます。）」の提供条件等を定めるものです。本サービスを利用するお客さま（以下「利用者」といいます。）は、本サービスのご利用を開始された時点から、本規約に同意したものとみなされます。</p>
            <br />
            <ol>
                <li>本サービスを通じて提供される全てのデータ、文章、音声、画像、映像、イラスト、情報等（以下、併せて「データ等」といいます。）に関する、著作権、商標権、肖像権を含む一切の権利は、当社、または当該権利を有する第三者に帰属します。</li>
                <li>利用者は、本サービスの利用に際し次の各号の行為を行ってはならないものとします。<br />
                <ul style={{listStyle: 'none'}}>
                    <li>①本サービスを通じて提供されるデータ等を、利用者以外の第三者に閲覧・利用させる行為</li>
                    <li>②本サービスのIDまたは認証用URLを、利用者以外の第三者に開示する行為</li>
                    <li>③公序良俗に反する行為、または公序良俗に反する情報を第三者に提供する行為</li>
                    <li>④他の利用者または第三者を誹謗中傷する行為</li>
                    <li>⑤他の利用者または第三者に不利益を与える行為</li>
                    <li>⑥当社または第三者の著作権その他の知的財産権および保護されるべき法的権利を侵害する行為</li>
                    <li>⑦他の利用者または第三者の財産、プライバシーを侵害する行為</li>
                    <li>⑧自己または第三者の営利を目的とする行為</li>
                    <li>⑨法令に違反しもしくは違反のおそれのある行為、または法令に違反しもしくは違反のおそれのある情報を第三者に提供する行為</li>
                    <li>⑩本サービスを通じて入手したデータ等の改変、翻案、編纂、修正、データベース化等を行う行為</li>
                    <li>⑪本サービスの運営を妨げるような行為</li>
                    <li>⑫本サービスの信用を毀損するような行為</li>
                    <li>⑬その他、当社が不適切と判断する行為</li>
                </ul>
                </li>
                <li>
                    本サービスの推奨環境は以下のとおりです。なお、推奨環境以外でのご利用についてはサポート対象外となります。
                    <ol>
                    <li>iPhone<br />
                        ＜OS＞iOS 9.3.6/10.3.4以上<br />
                        ＜ブラウザ＞OS標準ブラウザ</li>

                        <li>iPad<br />
                        ＜OS＞iOS 9.3.6/10.3.4～12.4.2 iPadOS 13.1以上<br />
                        ＜ブラウザ＞OS標準ブラウザ</li>

                        <li>Android<br />
                        ＜OS＞Android™ 5.0以上<br />
                        ＜ブラウザ＞OS標準ブラウザ（Chrome）</li>

                        <li>PC（Windows）<br />
                        ＜OS＞Windows 8.1/10<br />
                        ＜ブラウザ＞Internet Explorer 11以上、/Edge（最新版）/Chrome（最新版）</li>

                        <li>PC（Mac）<br />
                        ＜ブラウザ＞Safari（最新版）/Chrome（最新版）
                        </li>
                    </ol>
                </li>
                <li>
                    当社のサービスの状態、または利用者のご利用環境の状態（インターネット通信速度が遅い場合等）によっては、本サービスが提供できない場合があります。
                </li>
                <li>本サービスのうち当社は、本サービスの利用に必要な認証用URLを利用者に付与する場合があります。なお、認証用URLの再発行は致しません。</li>
                <li>1件の認証用URLで使用可能な端末（なお、同端末であってもブラウザが異なる場合は1台とみなします。）は5台までとします。当該台数を超えた利用が判明した場合、当社は本サービスの提供を停止します。</li>
                <li>利用者は、付与された本サービスのIDまたは認証用URLを適正に管理するものとし、これらを用いて本サービスが利用された場合、当該利用は、当該本サービスのIDまたは認証用URLが付与された利用者によるものとみなします。</li>
                <li>当社は、利用者に対し事前に何ら通知を行うことなく、データ等や本サービスの内容を変更することができるものとします。</li>
                <li>当社は、システムの保守点検、不可抗力、本サービスの運営状況または利用者が入居するマンションの管理会社、本サービスが付帯するマンション向けサービスの提供事業者及び当社の間における本サービスの提供に係る契約に関する事由（利用料の不払い、契約の終了等を指すが、これらに限られない。）、その他の予期できない事情により、利用者に対し事前に何ら通知を行うことなく、本サービスの全部または一部の提供を停止または中止することができるものとします。また、当社は、本項に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任を負いません。</li>
                <li>当社は、本サービスの運営を継続し難いと判断した場合には、本サービスを廃止することができるものとします。</li>
                <li>当社は、本サービスを提供するにあたって、利用者の通信機器本体等に関する情報（製造番号、機種名、品番等）、本サービスに関する利用履歴（アクセス履歴、閲覧履歴、設定情報等）等の情報（以下「利用情報等」といいます。）を取得します。取得した利用情報等は、本規約および別途当社が定めるプライバシーポリシーの規定に基づき、管理・保有・利用いたします。</li>
                <li>当社は、前項に基づき取得した利用情報等を、以下に定める目的に従って利用いたします。なお、本条に基づき取得した利用情報等を、本規約に定める目的以外の目的で利用する場合には、その都度、その利用目的を明らかにした上で、利用者から事前の同意を取得します。<br />
                   <ul style={{listStyle: 'none'}}>
                        <li>①本サービスの提供対象として当社が指定した集合住宅からの問い合わせへの対応のため</li>
                        <li>②利用者の利便性の向上、品質改善および有益なサービスの提供等を目的として、利用状況の分析、効果測定、その他各種マーケティング調査および分析を行うため</li>
                        <li>③データ等の提供者との間において、料金支払いおよび売上金の分配額を計算するため</li>
                        <li>④当社サービスの不正契約・不正利用（不正ID取得）の防止および発生時に調査等を行うため</li>
                        <li>⑤その他、当社サービスの提供に必要な業務のため</li>
                    </ul>
                </li>
                <li>利用者は、本サービスの利用に関し、自己の責めに帰すべき事由により、当社に対して損害を与えた場合、これを賠償する責任を負うものとします。</li>
                <li>利用者は、本サービスの利用に関し、他の利用者またはその他の第三者から、クレームや請求を受け、または紛争が生じた場合は自己の責任と費用負担でこれを解決するものとします。</li>
                <li>当社は、故意または重過失による場合を除き、当社による本サービスの提供の廃止、停止、利用不能または変更等、利用者が本サービスを利用したこと、または利用できなかったことにより利用者に生じた損害について、一切責任を負わないものとします。</li>
                <li>当社は、本サービスおよび本サービスに付随して提供されるデータ等が、利用者の期待する水準に達していること、特定の目的に適合していること、ウィルス等に感染していないこと、掲載情報が正確であること、真実であること等を含め、いかなる保証もしません。</li>
                <li>本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの部分は、継続して完全に効力を有するものとします。</li>
                <li>本サービスの利用ならびに本規約の適用および解釈は、日本法に準拠するものとします。また、利用者は、当社との間で本サービスまたは本規約について訴訟の必要が生じた場合、東京地方裁判所、または東京簡易裁判所を第一審の専属的合意管轄裁判所とすることに合意するものとします。</li>
                <li>本規約の内容は、当社の都合により、利用者への通知なしに変更されることがあります。その場合、当社は変更後の内容を本サービス内や、その他当社が運営するウェブサイトなどに掲載するものとし、利用者はこれを確認するものとします。また当該変更の後は、変更後の本規約に従い、当社は本サービスを提供するものとし、利用者はこれに従うものとします。</li>
            </ol>
            <p>
            発行日：2021年5月10日<br />
            株式会社ビューン
            </p>
        </>
    )
}

export default ViewnTerm;