import { withStyles } from '@material-ui/styles';
import { Button, Fab } from '@material-ui/core';
import React, { useCallback } from 'react';
import { FabProps } from '@material-ui/core/Fab/Fab';
import { useHistory } from 'react-router-dom';

export const CustomKeyButton = withStyles({
  root: {
    '&.MuiButtonBase-root': {
      color: 'var(--color-white)',
      backgroundColor: 'var(--color-key)',
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
      width: '100%',
      '&:hover': {
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
        backGround: 'var(--color-key)'
      }
    },
    '&:disabled': {
      color: 'var(--color-white)',
      backgroundColor: '#E0E0E0',
      width: '100%'
    }
  }
})(Fab);

type CustomLinkButtonProps = {
  to: string;
} & FabProps;

export const CustomLinkButton: React.FC<CustomLinkButtonProps> = props => {
  const history = useHistory();
  const onClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();

      if (props.onClick) {
        props.onClick(event);
      }
      history.push(props.to);
    },
    []
  );

  return <CustomKeyButton {...props} onClick={onClick} />;
};
