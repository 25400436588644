import React, { FC, useState, useEffect, useCallback } from 'react';
import {
  RadioGroup,
  Container,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Button
} from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import OccupancyLabel, { OccupancyLabelBox } from './OccupancyLabel';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import {
  ApplyHeading,
  ApplyLabel,
  ApplyCaption,
  ApplyImportantInformation
} from './Label';
import { CustomRadioButton, BlueRadio } from './CustomRadioButton';
import { CustomKeyButton, CustomLinkButton } from './CustomButton';
import { mapStateToProps, mapDispatchToProps } from '../container/applyForm';
import * as moment from 'moment-timezone';
import { CustomTextField } from './inputs/CustomTextField';
import ApplicationModal from './ApplyModal';
import { PayMethod } from './baseform/PayMethod';
import { ApplyTextField } from './baseform/ApplyTextField';
import { SelectGasStartTime } from './baseform/SelectGasStartTime';
import BackModal from '../container/backModal';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import jaLocale from 'date-fns/locale/ja';
import * as AutoKana from 'vanilla-autokana';
import { Path } from '../constants/path';
import { SumamoruPlan, SumamoruProjectType } from '../modules/property/types';
import StringUtils from '../utils/string-utils';

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const YEAR: number[] = [];
for (let i = 1901; i < 2020; i++) {
  YEAR.push(i);
}
const MONTH = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const useStyles = makeStyles(
  createStyles({
    container: {
      textAlign: 'left',
      padding: '0 0 20px',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#E0E0E0'
      },
      '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'var(--color-key)'
        }
      }
    },
    balloon: {
      backgroundColor: 'var(--color-sub)',
      color: 'var(--color-white)',
      fontSize: 12,
      fontWeight: 'bold',
      borderRadius: 4,
      padding: '7px 0 8px',
      lineHeight: '17px',
      textAlign: 'center',
      margin: '16px 0 0',
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        height: 0,
        width: 0,
        position: 'absolute',
        bottom: '-8px',
        left: 'calc(50% - 16px)',
        borderTop: '16px solid var(--color-sub)',
        borderRight: '16px solid transparent',
        borderLeft: '16px solid transparent'
      }
    },
    gasSeletctionContainer: {
      margin: '8px 0 0',
      '& .MuiTypography-root': {
        fontSize: 14
      }
    },
    confirmButton: {
      margin: '24px 0 0'
    },
    checkbox: {
      '& .MuiTypography-root': {
        color: 'var(--color-text)',
        fontSize: 14
      },
      '& .MuiCheckbox-root': {
        color: 'var(--color-key)'
      }
    },
    activeRadioBorder: {
      border: '2px solid var(--color-key) !important'
    },
    textField: {
      margin: '8px 0 0',
      '& input': {
        padding: '16px 14px',
        fontSize: '14px !important',
        '&::placeholder': {
          fontSize: '14px !important'
        }
      },
      '& p': {
        fontWeight: 'bold',
        color: 'var(--color-error) !important',
        margin: '8px 0 0 !important'
      }
    },
    backButtonContainer: {
      margin: '40px 0',
      textAlign: 'center'
    },
    backButton: {
      color: 'var(--color-gray-3)',
      textDecoration: 'none'
    },
    selectPlaceholder: {
      color: '#BDBDBD'
    },
    calendar: {
      '& .MuiPickersDay-daySelected': {
        color: 'white'
      }
    },
    termsCaption: {
      fontSize: 14,
      lineHeight: '21px',
      color: 'var(--color-text)',
      '& a': {
        textDecoration: 'none',
        color: 'var(--color-key)',
        fontWeight: 'bold'
      }
    }
  })
);

type Props = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const BaseForm: FC<Props> = ({
  use_og_gas,
  start_power_date,
  start_gas_date,
  start_gas_time,
  isCoodinatedGasStartDate,
  first_name,
  family_name,
  first_name_kana,
  family_name_kana,
  phone_number,
  phone_number_when_move,
  management_company_address,
  management_company_name,
  management_company_phonenumber,
  birthday,
  email,
  email_confirmation,
  pay_method,
  move_plan_date,
  updateParameter,
  property,
  gender,
  bitkeyEmail,
  bitkeyPhoneNumber,
  initialBirthday,
  isSameEmail,
  getTokens,
  getIds,
  getProperty
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [backModalOpen, setBackModalOpen] = useState(false);
  const [year = 1980, setYear] = useState<number | undefined>(undefined);
  const [month = 1, setMonth] = useState<number | undefined>(undefined);
  const [day, setDay] = useState<number | undefined>(undefined);
  const [lastday, setLastday] = useState<number[]>([]);
  const [phoneNumErrorMessage, setPhoneNumErrorMessage] = useState('');
  const [
    phoneNumWhenMoveErrorMessage,
    setPhoneNumWhenMoveErrorMessage
  ] = useState('');
  const [firstNameErrorMessage, setFirstNameErrorMessage] = useState('');
  const [familyNameErrorMessage, setFamilyNameErrorMessage] = useState('');
  const [firstNameKanaErrorMessage, setFirstNameKanaErrorMessage] = useState(
    ''
  );
  const [familyNameKanaErrorMessage, setFamilyNameKanaErrorMessage] = useState(
    ''
  );
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [gasDateErrorMessage, setGasDateErrorMessage] = useState('');
  const [powerDateErrorMessage, setPowerDateErrorMessage] = useState('');
  const [
    isPhoneNumberWhenMoveIsValidated,
    setPhoneNumberWhenMoveIsValidated
  ] = useState('');
  const [isPhoneNumberIsValidated, setPhoneNumberIsValidated] = useState('');
  const [emailConfErrorMessage, setEmailConfErrorMessage] = useState('');

  const [autoKana, setAutoKana] = useState<{
    familyName: AutoKana.AutoKana;
    firstName: AutoKana.AutoKana;
  }>();

  useEffect(() => {
    setAutoKana({
      familyName: AutoKana.bind(
        '#fimaily-name-input',
        '#fimaily-name-kana-input',
        { katakana: true }
      ),
      firstName: AutoKana.bind('#first-name-input', '#first-name-kana-input', {
        katakana: true
      })
    });
  }, []);

  const styles = useStyles({});
  moment.updateLocale('ja', {
    weekdaysShort: ['日', '月', '火', '水', '木', '金', '土'],
    months: [
      '1月',
      '2月',
      '3月',
      '4月',
      '5月',
      '6月',
      '7月',
      '8月',
      '9月',
      '10月',
      '11月',
      '12月'
    ]
  });
  const movePlanDate = moment(move_plan_date).format('YYYY年MM月DD日(ddd)');
  const gasStartMinDate = moment()
    .add(5, 'day')
    .format('YYYY-MM-DD');
  const phoneRegex = /^0{1}\d{8,13}$/;
  const onlyNumber = /^\d+$/;
  const zenkaku = /^[^\x01-\x7E\uFF65-\uFF9F\u002D]+$/;
  const zenkakuKana = /^[\u30a1-\u30f6\u30fc]+$/;

  const handleFamilyNameChange = useCallback(
    (name: string) => {
      updateParameter('family_name', name);
      updateParameter(
        'family_name_kana',
        autoKana && autoKana.familyName.getFurigana()
      );
    },
    [updateParameter, autoKana]
  );

  const handleFirstNameChange = useCallback(
    (name: string) => {
      updateParameter('first_name', name);
      updateParameter(
        'first_name_kana',
        autoKana && autoKana.firstName.getFurigana()
      );
    },
    [updateParameter, autoKana]
  );

  const handleDateChange = (date: Date | null) => {
    const value = date !== null ? date : undefined;
    updateParameter('start_power_date', value && value.toString());
    // ガスを申し込めるプロジェクト種別 かつ ガスを申し込むを選択している かつ ガスの開栓日が未入力の場合
    if (
      !isNotUseGas &&
      use_og_gas === '1' &&
      (!start_gas_date || start_gas_date.length === 0)
    ) {
      updateParameter('start_gas_date', value && value.toString());
    }
  };

  const handleGasDateChange = (date: Date | null) => {
    const value = date !== null ? date : undefined;
    updateParameter('start_gas_date', value && value.toString());
  };

  const handlePhoneNumberWhenMove = (phoneNumber: string) => {
    if (isPhoneNumberWhenMoveIsValidated === 'true') {
      if (phoneNumber === '') {
        setPhoneNumWhenMoveErrorMessage('');
      } else if (phoneNumber.match(zenkaku)) {
        setPhoneNumWhenMoveErrorMessage('半角数字で入力してください');
      } else if (!onlyNumber.test(phoneNumber)) {
        setPhoneNumWhenMoveErrorMessage('半角数字で入力してください');
      } else if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumWhenMoveErrorMessage('有効な電話番号を入力してください');
      } else {
        setPhoneNumWhenMoveErrorMessage('');
      }
    }
    updateParameter('phone_number_when_move', phoneNumber);
  };

  const firstCheckPhoneNumberWhenMoveIsValidated = (phoneNumber: string) => {
    if (isPhoneNumberWhenMoveIsValidated !== 'true') {
      if (phoneNumber === '') {
        setPhoneNumWhenMoveErrorMessage('');
      } else if (phoneNumber.match(zenkaku)) {
        setPhoneNumWhenMoveErrorMessage('半角数字で入力してください');
      } else if (!onlyNumber.test(phoneNumber)) {
        setPhoneNumWhenMoveErrorMessage('半角数字で入力してください');
      } else if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumWhenMoveErrorMessage('有効な電話番号を入力してください');
      } else {
        setPhoneNumWhenMoveErrorMessage('');
        if (!phone_number || phone_number.length === 0) {
          updateParameter('phone_number', phoneNumber);
        }
      }
    }
    setPhoneNumberWhenMoveIsValidated('true');
  };

  const checkFamilyNameError = (familyName: string) => {
    if (familyName === '') {
      setFamilyNameErrorMessage('');
    } else if (!familyName.match(zenkaku)) {
      setFamilyNameErrorMessage('全角で入力してください');
    } else if (familyName.length > 10) {
      setFamilyNameErrorMessage('10文字以内で入力してください');
    } else {
      setFamilyNameErrorMessage('');
    }
    checkFirstNameError(first_name);
  };

  const checkFirstNameError = (firstName: string) => {
    if (firstName === '') {
      setFirstNameErrorMessage('');
    } else if (!firstName.match(zenkaku)) {
      setFirstNameErrorMessage('全角で入力してください');
    } else if (firstName.length > 10) {
      setFirstNameErrorMessage('10文字以内で入力してください');
    } else if (firstName.length + family_name.length > 14) {
      setFirstNameErrorMessage('氏名の合計を14文字以内で入力してください。');
    } else {
      setFirstNameErrorMessage('');
    }
    checkFamilyNameKanaError(family_name_kana);
  };

  const checkFamilyNameKanaError = (familyNameKana: string) => {
    if (familyNameKana === '') {
      setFamilyNameKanaErrorMessage('');
    } else if (!familyNameKana.match(zenkakuKana)) {
      setFamilyNameKanaErrorMessage('全角カナで入力してください');
    } else if (familyNameKana.length > 10) {
      setFamilyNameKanaErrorMessage('10文字以内で入力してください');
    } else {
      setFamilyNameKanaErrorMessage('');
    }
    checkFirstNameKanaError(first_name_kana);
  };

  const checkFirstNameKanaError = (firstNameKana: string) => {
    if (firstNameKana === '') {
      setFirstNameKanaErrorMessage('');
    } else if (!firstNameKana.match(zenkakuKana)) {
      setFirstNameKanaErrorMessage('全角カナで入力してください');
    } else if (firstNameKana.length > 10) {
      setFirstNameKanaErrorMessage('10文字以内で入力してください');
    } else if (firstNameKana.length + family_name_kana.length > 14) {
      setFirstNameKanaErrorMessage(
        'フリガナの合計を14文字以内で入力してください。'
      );
    } else {
      setFirstNameKanaErrorMessage('');
    }
  };

  const handlePhoneNumber = (phoneNumber: string) => {
    if (isPhoneNumberIsValidated === 'true') {
      if (phoneNumber === '') {
        setPhoneNumErrorMessage('');
      } else if (phoneNumber.match(zenkaku)) {
        setPhoneNumErrorMessage('半角数字で入力してください');
      } else if (!onlyNumber.test(phoneNumber)) {
        setPhoneNumErrorMessage('半角数字で入力してください');
      } else if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumErrorMessage('有効な電話番号を入力してください');
      } else {
        setPhoneNumErrorMessage('');
      }
    }
    updateParameter('phone_number', phoneNumber);
  };

  const firstCheckPhoneNumberIsValidated = (phoneNumber: string) => {
    if (isPhoneNumberIsValidated !== 'true') {
      if (phoneNumber === '') {
        setPhoneNumErrorMessage('');
      } else if (phoneNumber.match(zenkaku)) {
        setPhoneNumErrorMessage('半角数字で入力してください');
      } else if (!onlyNumber.test(phoneNumber)) {
        setPhoneNumErrorMessage('半角数字で入力してください');
      } else if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumErrorMessage('有効な電話番号を入力してください');
      } else {
        setPhoneNumErrorMessage('');
      }
    }
    setPhoneNumberIsValidated('true');
  };

  const handleEmailConf = (emailComfirmation: string) => {
    if (emailComfirmation === '') {
      setEmailConfErrorMessage('');
    } else if (emailComfirmation !== email) {
      setEmailConfErrorMessage('メールアドレスが一致しません');
    } else {
      setEmailConfErrorMessage('');
    }

    updateParameter('email_confirmation', emailComfirmation);
  };

  const handleEmail = (email: string) => {
    if (email === '') {
      setEmailErrorMessage('');
    } else if (email.length > 60) {
      setEmailErrorMessage('60文字以内で入力してください');
    } else if (!StringUtils.isEmail(email)) {
      setEmailErrorMessage('有効なメールアドレスを入力してください');
    } else {
      setEmailErrorMessage('');
    }
    updateParameter('email', email);
  };

  const handleIsCoodinateGasStartDate = (flg: boolean) => {
    updateParameter('isCoodinatedGasStartDate', flg);
  };

  const setGasDateError = useCallback(
    (startGasDate: string) => {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      const returnDateFrom = new Date(startGasDate);
      returnDateFrom.setHours(0);
      returnDateFrom.setMinutes(0);
      returnDateFrom.setSeconds(0);
      returnDateFrom.setMilliseconds(0);
      const maxDate = new Date(2100, 0, 1);
      const minDate = new Date(gasStartMinDate);
      minDate.setHours(0);
      minDate.setMinutes(0);
      minDate.setSeconds(0);
      minDate.setMilliseconds(0);
      if (startGasDate === 'Invalid Date' || returnDateFrom > maxDate) {
        setGasDateErrorMessage('有効な日付を入力してください。');
      } else if (returnDateFrom < today) {
        setGasDateErrorMessage('過去の日付は選択できません。');
      } else if (returnDateFrom < minDate) {
        setGasDateErrorMessage('申込日より4日間以内の日付は選択できません。');
      } else {
        setGasDateErrorMessage('');
      }
    },
    [gasStartMinDate, setGasDateErrorMessage]
  );
  const setPowerDateError = (startPowerDate: string) => {
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    const returnDateFrom = new Date(startPowerDate);
    returnDateFrom.setHours(0);
    returnDateFrom.setMinutes(0);
    returnDateFrom.setSeconds(0);
    returnDateFrom.setMilliseconds(0);
    const maxDate = new Date(2100, 0, 1);
    if (startPowerDate === 'Invalid Date' || returnDateFrom > maxDate) {
      setPowerDateErrorMessage('有効な日付を入力してください。');
    } else if (returnDateFrom < today) {
      setPowerDateErrorMessage('過去の日付は選択できません。');
    } else {
      setPowerDateErrorMessage('');
    }
  };

  // 初回のみ実行
  useEffect(() => {
    if (start_power_date === '') {
      handleDateChange(move_plan_date);
    }
    if (start_gas_date === '') {
      handleGasDateChange(move_plan_date);
    }
  }, []);

  useEffect(() => {
    if (year !== undefined && month !== undefined) {
      if (day === undefined) setDay(1);
      const lastday = new Date(year, month, 0).getDate();
      const days: number[] = [];
      for (let i = 0; i < lastday; i++) {
        days.push(i + 1);
      }
      if (day !== undefined && day > lastday) {
        setDay(1);
      }
      setLastday(days);
    }
  }, [year, month, day]);

  useEffect(() => {
    if (year !== undefined && month !== undefined && day !== undefined) {
      const _month = month < 10 ? `0${month}` : `${month}`;
      const _day = day < 10 ? `0${day}` : `${day}`;

      updateParameter('birthday', `${year}${_month}${_day}`);
    }
  }, [year, month, day, updateParameter]);

  useEffect(() => {
    updateParameter('isPhoneNu', `${bitkeyEmail}`);
  }, [updateParameter, bitkeyEmail]);

  /**
   * 電話番号でサインイン、サインアップした場合に実行する初期化処理
   */
  useEffect(() => {
    if (bitkeyPhoneNumber) {
      updateParameter('isSameEmail', false);
      updateParameter('phone_number', bitkeyPhoneNumber);
      updateParameter('phone_number_when_move', bitkeyPhoneNumber);
    }
  }, [updateParameter, bitkeyPhoneNumber]);

  /**
   * 本人確認に利用した誕生日を初期値に詰めておく
   */
  useEffect(() => {
    if (initialBirthday) {
      updateParameter('birthday', initialBirthday);
      const initialYear = initialBirthday.slice(0, 4);
      const initialMonth = initialBirthday.slice(4, 6);
      const initialDay = initialBirthday.slice(6, 8);
      setYear(Number(initialYear));
      setMonth(Number(initialMonth));
      setDay(Number(initialDay));
    }
  }, [updateParameter, initialBirthday]);

  useEffect(() => {
    if (use_og_gas === '0' && pay_method === '2') {
      updateParameter('pay_method', '0');
    }
  }, [updateParameter, use_og_gas, pay_method]);

  useEffect(() => {
    if (property.allSpaceId === '') {
      getIds();
    }
    if (bitkeyEmail === '') {
      getTokens();
    }
    if (
      bitkeyEmail !== '' &&
      property.allSpaceId !== '' &&
      property.address === ''
    ) {
      getProperty();
    }
  }, [bitkeyEmail, property, getIds, getTokens, getProperty]);

  const isOgExternal =
    property.sumamoruProjectType === SumamoruProjectType.OGExternal;

  const isNotUseGas =
    property.sumamoruProjectType === SumamoruProjectType.OGExternal ||
    property.sumamoruProjectType ===
      SumamoruProjectType.OGInternalOnlyElectricity;

  useEffect(() => {
    isNotUseGas && updateParameter('use_og_gas', '0');
  }, [isNotUseGas]);

  useEffect(() => {
    if (
      start_power_date &&
      family_name &&
      family_name_kana &&
      first_name &&
      first_name_kana &&
      phone_number &&
      gender &&
      birthday
    ) {
      setPhoneNumberIsValidated('true');
      setPhoneNumberWhenMoveIsValidated('true');
    }
  }, [
    start_power_date,
    family_name,
    family_name_kana,
    first_name,
    first_name_kana,
    phone_number,
    phoneNumErrorMessage,
    gender,
    birthday
  ]);
  const submitValidation = () => {
    if (property.hasElectricContract) {
      if (use_og_gas === '1') {
        return (
          start_power_date === '' ||
          (!isCoodinatedGasStartDate && start_gas_time === '') ||
          (!isCoodinatedGasStartDate && start_gas_date === '') ||
          phone_number_when_move === '' ||
          family_name === '' ||
          family_name_kana === '' ||
          first_name === '' ||
          first_name_kana === '' ||
          phone_number === '' ||
          phoneNumErrorMessage !== '' ||
          isPhoneNumberIsValidated !== 'true' ||
          phoneNumWhenMoveErrorMessage !== '' ||
          isPhoneNumberWhenMoveIsValidated !== 'true' ||
          firstNameErrorMessage !== '' ||
          familyNameErrorMessage !== '' ||
          firstNameKanaErrorMessage !== '' ||
          familyNameKanaErrorMessage !== '' ||
          gasDateErrorMessage !== '' ||
          powerDateErrorMessage !== '' ||
          gender === '' ||
          birthday === '' ||
          (isSameEmail
            ? false
            : email === '' ||
              emailErrorMessage !== '' ||
              email_confirmation === '' ||
              email !== email_confirmation)
        );
      } else {
        return (
          start_power_date === '' ||
          start_power_date === undefined ||
          family_name === '' ||
          family_name_kana === '' ||
          first_name === '' ||
          first_name_kana === '' ||
          phone_number === '' ||
          phoneNumErrorMessage !== '' ||
          isPhoneNumberIsValidated !== 'true' ||
          firstNameErrorMessage !== '' ||
          familyNameErrorMessage !== '' ||
          firstNameKanaErrorMessage !== '' ||
          familyNameKanaErrorMessage !== '' ||
          powerDateErrorMessage !== '' ||
          gender === '' ||
          birthday === '' ||
          (isSameEmail
            ? false
            : email === '' ||
              emailErrorMessage !== '' ||
              email_confirmation === '' ||
              email !== email_confirmation)
        );
      }
    } else {
      return (
        family_name === '' ||
        family_name_kana === '' ||
        first_name === '' ||
        first_name_kana === '' ||
        phone_number === '' ||
        phoneNumErrorMessage !== '' ||
        isPhoneNumberIsValidated !== 'true' ||
        firstNameErrorMessage !== '' ||
        familyNameErrorMessage !== '' ||
        firstNameKanaErrorMessage !== '' ||
        familyNameKanaErrorMessage !== '' ||
        gender === '' ||
        birthday === '' ||
        (isSameEmail
          ? false
          : email === '' ||
            emailErrorMessage !== '' ||
            email_confirmation === '' ||
            email !== email_confirmation)
      );
    }
  };
  const isOgDefaut =
    property.sumamoruPlan === SumamoruPlan.OG_Default || !property.sumamoruPlan;

  return (
    <div>
      {property.hasElectricContract && (
        <>
          {!isNotUseGas && (
            <Container className={styles.container}>
              <ApplyHeading
                id={'gas'}
                text={'大阪ガスの都市ガスもセットで申し込みますか？'}
              />
              <ApplyHeading
                id={'gas'}
                text={
                  '（プロパンガス物件については、「ガスの開栓申し込みはしない/プロパンガス物件」を、選択ください）'
                }
              />
              <RadioGroup
                className={styles.gasSeletctionContainer}
                aria-label="gas"
                name="orderGas"
                value={use_og_gas}
                onChange={e => updateParameter('use_og_gas', e.target.value)}
              >
                <CustomRadioButton
                  className={use_og_gas === '1' ? styles.activeRadioBorder : ''}
                  value={'1'}
                  control={<BlueRadio />}
                  label="ガスの開栓申し込みも一緒に行う"
                />
                <CustomRadioButton
                  className={use_og_gas === '0' ? styles.activeRadioBorder : ''}
                  value={'0'}
                  control={<BlueRadio />}
                  label="ガスの開栓申し込みはしない/プロパンガス物件"
                />
              </RadioGroup>
            </Container>
          )}

          <Container className={styles.container}>
            <ApplyHeading text={'お引越し情報'} id={'moving'} />
            <>
              <ApplyLabel text={'入居予定日'} />
              <OccupancyLabel
                style={{ margin: '8px 0 0 !important' }}
                label=""
                desc={movePlanDate}
              />
            </>
            <ApplyLabel text={'電気：利用開始日'} />
            <MuiPickersUtilsProvider locale={jaLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                color="primary"
                className={styles.calendar}
                id="date-picker-dialog"
                format="yyyy/MM/dd"
                helperText={powerDateErrorMessage}
                value={start_power_date}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                cancelLabel={<span style={{ color: 'black' }}>キャンセル</span>}
                onError={() => setPowerDateError(start_power_date)}
                disablePast
                disableToolbar
                style={{ width: '100%' }}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>

            {!isNotUseGas && use_og_gas === '1' && (
              <>
                <ApplyLabel text={'ガス：開栓希望日・時間帯'} />
                <ApplyCaption
                  text={'※ガス開栓にはお客様の立ち会いが必要となります。'}
                />
                <MuiPickersUtilsProvider locale={jaLocale} utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    color="primary"
                    helperText={gasDateErrorMessage}
                    className={styles.calendar}
                    id="date-picker-dialog"
                    format="yyyy/MM/dd"
                    value={start_gas_date}
                    onChange={handleGasDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                    onError={() => setGasDateError(start_gas_date || '')}
                    cancelLabel={
                      <span style={{ color: 'black' }}>キャンセル</span>
                    }
                    minDate={gasStartMinDate}
                    disablePast
                    disableToolbar
                    disabled={isCoodinatedGasStartDate}
                    style={{ width: '100%' }}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
                <SelectGasStartTime
                  start_gas_time={start_gas_time || ''}
                  updateParameter={updateParameter}
                  disabled={isCoodinatedGasStartDate}
                />
                <ApplyCaption
                  text={
                    '※申込日から4日間以内のガス開栓をご希望の場合、サイトでのお申し込み後に大阪ガスへ「スマモル賃貸プランであること」「開栓希望日」を合わせてご連絡、ご相談ください。'
                  }
                />
                <FormControlLabel
                  className={styles.checkbox}
                  control={
                    <Checkbox
                      color="primary"
                      checked={isCoodinatedGasStartDate}
                      onChange={e =>
                        handleIsCoodinateGasStartDate(e.target.checked)
                      }
                    />
                  }
                  label={'開栓希望日を大阪ガスに直接連絡する'}
                />
                {isCoodinatedGasStartDate && (
                  <>
                    <ApplyImportantInformation
                      text={`次の連絡先に「スマモル賃貸プランであること」「開栓希望日」を合わせてご連絡、ご相談ください。`}
                    />
                    <ApplyImportantInformation
                      text={`連絡先：0120-0-94817 (大阪ガスお客さまセンター)`}
                    />
                    <ApplyImportantInformation
                      text={`受付時間 月～土9:00～19:00、日祝9:00～17:00`}
                    />
                  </>
                )}

                <ApplyLabel text={'ガス開栓日に連絡可能な電話番号'} />

                <CustomTextField
                  className={styles.textField}
                  margin="normal"
                  placeholder="08012345678"
                  onBlur={e =>
                    firstCheckPhoneNumberWhenMoveIsValidated(e.target.value)
                  }
                  value={phone_number_when_move}
                  onChange={e => handlePhoneNumberWhenMove(e.target.value)}
                  style={{ width: '100%', marginTop: 8 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  error={phoneNumWhenMoveErrorMessage !== ''}
                  helperText={phoneNumWhenMoveErrorMessage}
                  variant="outlined"
                  type="tel"
                />
              </>
            )}
          </Container>
        </>
      )}

      <Container className={styles.container}>
        <ApplyHeading text={'お客様情報'} id={'userinfo'} />

        <ApplyLabel text={'氏名'} />
        <ApplyTextField
          id={'fimaily-name-input'}
          placeholder={'姓'}
          value={family_name}
          error={familyNameErrorMessage !== ''}
          helperText={familyNameErrorMessage}
          onChange={e => handleFamilyNameChange(e)}
          onBlur={e => checkFamilyNameError(e)}
        />
        <ApplyTextField
          id={'first-name-input'}
          placeholder={'名'}
          value={first_name}
          error={firstNameErrorMessage !== ''}
          helperText={firstNameErrorMessage}
          onChange={e => handleFirstNameChange(e)}
          onBlur={e => checkFirstNameError(e)}
        />
        <ApplyLabel text={'フリガナ'} />
        <ApplyTextField
          id={'fimaily-name-kana-input'}
          placeholder={'セイ'}
          value={family_name_kana}
          error={familyNameKanaErrorMessage !== ''}
          helperText={familyNameKanaErrorMessage}
          onChange={e => updateParameter('family_name_kana', e)}
          onBlur={e => checkFamilyNameKanaError(e)}
        />
        <ApplyTextField
          id={'first-name-kana-input'}
          placeholder={'メイ'}
          value={first_name_kana}
          error={firstNameKanaErrorMessage !== ''}
          helperText={firstNameKanaErrorMessage}
          onBlur={e => checkFirstNameKanaError(e)}
          onChange={e => updateParameter('first_name_kana', e)}
        />

        <ApplyCaption text={'全角カタカナで入力'} />

        <ApplyLabel text={'生年月日'} />
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <FormControl
              margin="normal"
              style={{ width: '100%', marginTop: 8 }}
              variant="outlined"
            >
              <Select
                value={year}
                onChange={e => setYear(e.target.value as number)}
                displayEmpty
              >
                <MenuItem value={undefined} disabled>
                  <span className={styles.selectPlaceholder}>年</span>
                </MenuItem>
                {YEAR.reverse().map(y => {
                  return (
                    <MenuItem key={`y${y}`} value={y}>
                      {y}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              margin="normal"
              style={{ width: '100%', marginTop: 8 }}
              variant="outlined"
            >
              <Select
                value={month}
                onChange={e => setMonth(e.target.value as number)}
                displayEmpty
              >
                <MenuItem value={undefined} disabled>
                  <span className={styles.selectPlaceholder}>月</span>
                </MenuItem>
                {MONTH.map(m => {
                  return (
                    <MenuItem key={`m${m}`} value={m}>
                      {m}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              margin="normal"
              style={{ width: '100%', marginTop: 8 }}
              variant="outlined"
            >
              {day ? (
                <Select
                  value={day}
                  onChange={e => setDay(e.target.value as number)}
                  displayEmpty
                  disabled={lastday.length === 0}
                >
                  <MenuItem value={undefined} disabled>
                    <span className={styles.selectPlaceholder}>日</span>
                  </MenuItem>
                  {lastday &&
                    lastday.map(d => (
                      <MenuItem key={`m${d}`} value={d}>
                        {d}
                      </MenuItem>
                    ))}
                </Select>
              ) : (
                <></>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <ApplyLabel text={'電話番号'} />
        <CustomTextField
          className={styles.textField}
          margin="normal"
          placeholder="08012345678"
          style={{ width: '100%' }}
          value={phone_number}
          InputLabelProps={{
            shrink: true
          }}
          onBlur={e => firstCheckPhoneNumberIsValidated(e.target.value)}
          onChange={e => handlePhoneNumber(e.target.value)}
          variant="outlined"
          error={phoneNumErrorMessage !== ''}
          helperText={phoneNumErrorMessage}
          type="tel"
        />

        <ApplyLabel text={'性別'} />
        <FormControl
          margin="normal"
          variant="outlined"
          style={{ width: '100%' }}
        >
          <Select
            value={gender}
            onChange={e => updateParameter('gender', e.target.value as string)}
            displayEmpty
          >
            <MenuItem value="" disabled>
              <span className={styles.selectPlaceholder}>性別を選択</span>
            </MenuItem>
            <MenuItem value={'0'}>男性</MenuItem>
            <MenuItem value={'1'}>女性</MenuItem>
            <MenuItem value={'2'}>無回答</MenuItem>
          </Select>
        </FormControl>

        <ApplyLabel text={'メールアドレス'} />
        {bitkeyEmail && (
          <FormControlLabel
            className={styles.checkbox}
            control={
              <Checkbox
                color="primary"
                checked={isSameEmail}
                onChange={e => updateParameter('isSameEmail', e.target.checked)}
              />
            }
            label={'Bitkeyアカウントと同じメールアドレスを使用'}
          />
        )}
        {isSameEmail ? (
          <OccupancyLabelBox desc={bitkeyEmail} />
        ) : (
          <div>
            <ApplyTextField
              placeholder={'メールアドレス'}
              value={email}
              onChange={e => handleEmail(e)}
              error={emailErrorMessage !== ''}
              helperText={emailErrorMessage}
            />
            <ApplyTextField
              placeholder={'メールアドレス(確認用)'}
              value={email_confirmation}
              error={emailConfErrorMessage !== ''}
              helperText={emailConfErrorMessage}
              onChange={e => handleEmailConf(e)}
            />
          </div>
        )}
      </Container>

      {property.hasElectricContract && (
        <Container className={styles.container}>
          <PayMethod
            isOgExternal={isOgExternal}
            use_og_gas={use_og_gas}
            pay_method={pay_method}
            updateParameter={(key, value) => updateParameter(key, value)}
          />
        </Container>
      )}

      <p className={styles.termsCaption}>
        {isOgExternal && (
          <>
            <ApplyHeading text={'ご契約アンペアについて'} id={'paymethod'} />
            <br />
            •契約電流（契約上使用できる最大電流（アンペア））は、
            <u>原則として当該需要場所における直近の需給契約終了時点の値</u>
            となります。適用される契約電流については、お手続き完了後に送付される「電気ご契約内容に関するご案内」にてご確認ください。なお、入居後に契約電流の変更を希望の場合は、供給開始後に大阪ガスまでご連絡ください。
            <br />
            •当該需要場所における直近の需給契約終了時点の契約電流の値が不明な場合、お客さまにご希望の契約電流を確認する為、ご連絡させて頂くことがあります。
            <u>
              ご連絡が取れない場合、お客さまからご希望の契約電流のご連絡をいただくまでの間、契約保留となり手続きが進められない状態となりますのでご注意下さい。
            </u>
            <br />
          </>
        )}
        上記でご入力いただいた情報は、
        <br />
        ・駆けつけサービス
        <br />
        {isOgDefaut && (
          <>
            ・優待・割引サービス
            <br />
          </>
        )}
        ・すこやかダイヤル
        <br />
        {property.sumamoruPlan === SumamoruPlan.OG_Viewn && (
          <>
            ・雑誌・マンガ読み放題サービス
            <br />
          </>
        )}
        で利用されます。
        <br />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            isOgExternal
              ? 'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/clause_kaketsuke_other.pdf'
              : 'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/clause_kaketsuke.pdf'
          }
        >
          駆けつけサービス契約約款
        </a>
        ・
        {isOgDefaut && (
          <>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/rules_mechato.pdf'
              }
            >
              優待・割引サービス(めちゃとクーポン)利用規約
            </a>
            ・
          </>
        )}
        <>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/rules_sukoyakadial.pdf"
          >
            すこやかダイヤル利用規約
          </a>
          ・
        </>
        {property.sumamoruPlan === SumamoruPlan.OG_WLock && <></>}
        {property.sumamoruPlan === SumamoruPlan.OG_Viewn && (
          <>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/rules_viewn.pdf'
              }
            >
              ビューン読み放題マンション等利用規約
            </a>
            ・
          </>
        )}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={'https://www.osakagas.co.jp/info/privacy.html'}
        >
          プライバシーポリシー
        </a>
        にご同意の上、申込内容のご確認へお進みください。
        <br />
        また、お客様が入居契約した物件の管理会社もご入力いただいた情報を利用します。
        <br />
      </p>

      {property.hasElectricContract ? (
        <CustomKeyButton
          disabled={submitValidation()}
          className={styles.confirmButton}
          variant="extended"
          size="large"
          onClick={() => setModalOpen(true)}
        >
          申込内容の確認へ
        </CustomKeyButton>
      ) : (
        <CustomLinkButton
          disabled={submitValidation()}
          className={styles.confirmButton}
          variant="extended"
          size="large"
          to={Path.application.confirm}
        >
          申込内容の確認へ
        </CustomLinkButton>
      )}

      <div className={styles.backButtonContainer}>
        <Button
          className={styles.backButton}
          onClick={() => setBackModalOpen(true)}
        >
          戻る
        </Button>
      </div>

      <BackModal isOpen={backModalOpen} setIsOpen={e => setBackModalOpen(e)} />
      <ApplicationModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        management_company_address={management_company_address}
        management_company_name={management_company_name}
        management_company_phonenumber={management_company_phonenumber}
        sumamoruPlan={property.sumamoruPlan}
      />
    </div>
  );
};

export default BaseForm;
