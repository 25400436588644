import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import { AnyAction } from 'redux';

export const setUserFromCookie = (): ThunkAction<
  {},
  {},
  {},
  AnyAction
> => async dispatch => {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const c = cookie.split('=');

    switch (c[0]) {
      case 'access_token': {
        dispatch(Actions.setAccessToken(c[1]));
        break;
      }
      case 'user_id': {
        dispatch(Actions.setUserId(c[1]));
        break;
      }
      case 'email': {
        dispatch(Actions.setEmail(c[1]));
        break;
      }
      case 'phone_number': {
        dispatch(Actions.setPhoneNumber(c[1]));
        break;
      }
      case 'refresh_token': {
        dispatch(Actions.setRefreshToken(c[1]));
        break;
      }
      default: {
        break;
      }
    }
  }
};
