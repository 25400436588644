import ActionType, { SigninErrorState, TextFieldStatus } from './types';
import { ActionWithPayload } from '../utils/types';

export const updateSigninIdentifyValue = (
  email: string
): ActionWithPayload<ActionType.updateSigninIdentifyValue, string> => ({
  type: ActionType.updateSigninIdentifyValue,
  payload: email
});

export const updateSigninPassword = (
  password: string
): ActionWithPayload<ActionType.updateSigninPassword, string> => ({
  type: ActionType.updateSigninPassword,
  payload: password
});

export const validateSigninIdentifyValue = (
  status: TextFieldStatus
): ActionWithPayload<
  ActionType.validateSigninIdentifyValue,
  TextFieldStatus
> => ({
  type: ActionType.validateSigninIdentifyValue,
  payload: status
});

export const validateSigninPassword = (
  status: TextFieldStatus
): ActionWithPayload<ActionType.validateSigninPassword, TextFieldStatus> => ({
  type: ActionType.validateSigninPassword,
  payload: status
});

export const startToSignin = (): ActionWithPayload<
  ActionType.startToSignin,
  {}
> => ({
  type: ActionType.startToSignin,
  payload: {}
});

export const signinSuccess = (): ActionWithPayload<
  ActionType.signinSuccess,
  {}
> => ({
  type: ActionType.signinSuccess,
  payload: {}
});

export const signinFailed = (
  error: SigninErrorState
): ActionWithPayload<ActionType.signinFailed, SigninErrorState> => ({
  type: ActionType.signinFailed,
  payload: error
});

export const clearSigninStatus = (): ActionWithPayload<
  ActionType.clearSigninStatus,
  {}
> => ({
  type: ActionType.clearSigninStatus,
  payload: {}
});

export type LoginAction =
  | ReturnType<typeof updateSigninIdentifyValue>
  | ReturnType<typeof validateSigninIdentifyValue>
  | ReturnType<typeof updateSigninPassword>
  | ReturnType<typeof validateSigninPassword>
  | ReturnType<typeof startToSignin>
  | ReturnType<typeof signinSuccess>
  | ReturnType<typeof signinFailed>
  | ReturnType<typeof clearSigninStatus>;
