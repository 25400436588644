import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import ViewnTerm from '../../components/atoms/Terms/ViewnTerm';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box',
      fontSize: 14,
      '& h1': {
        color: 'var(--color-text)',
        fontSize: 24,
        margin: '40px 0 0'
      },
      '& h2': {
        color: 'var(--color-text)',
        fontSize: 18,
        margin: '20px 0 0'
      },
      '& h3': {
        color: 'var(--color-text)',
        fontSize: 16,
        fontWeight: 'normal'
      },
      '& table': {
        width: '100%',
        margin: '12px 0 0',
        border: '2px solid #333',
        borderCollapse: 'collapse'
      },
      '& tr': {
        margin: 0,
        textAlign: 'left'
      },
      '& th': {
        color: '#333',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        border: '1px solid #333',
        borderWidth: '0px 1px 1px 0px',
        borderCollapse: 'collapse',
        padding: '16px 8px',
        margin: 0,
        width: '40%'
      },
      '& td': {
        color: 'var(--color-text)',
        fontSize: 14,
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '20px',
        border: '1px solid #333',
        borderCollapse: 'collapse',
        borderWidth: '0px 0px 1px',
        padding: '16px 8px',
        margin: 0,
        wordBreak: 'break-all',
        width: '60%'
      }
    }
  })
);

const ViewnTerms: React.FC = () => {
  const styles = useStyles({});

  return (
    <>
      <div className={styles.container}>
        <ViewnTerm />
      </div>
    </>
  );
};

export default ViewnTerms;
