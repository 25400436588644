import { ThunkAction } from 'redux-thunk';
import * as Actions from './actions';
import { AnyAction } from 'redux';
import { AppState } from '../../store';
import { bitlockVoApiPath, bitlockVoApiKey } from '../../config/baseConfig';
import { PrefectureJPMap } from '../../utils/address-types';
import { Property } from './types';

export const setIdsFromCookie = (): ThunkAction<
  {},
  {},
  {},
  AnyAction
> => async dispatch => {
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const c = cookie.split('=');

    switch (c[0]) {
      case 'all_space_id': {
        dispatch(Actions.setAllSpaceId(c[1]));
        break;
      }
      case 'contract_id': {
        dispatch(Actions.setContractId(c[1]));
        break;
      }
      default: {
        break;
      }
    }
  }
};

export const getProperty = (): ThunkAction<
  Promise<void>,
  AppState,
  {},
  AnyAction
> => async (dispatch, getState) => {
  try {
    dispatch(Actions.startToFetchProperty());
    const { allSpaceId, contractId } = getState().property.property;

    if (allSpaceId === '' || contractId === '') {
      dispatch(Actions.failedSetProperty());
      return;
    }

    const endpoint = `${bitlockVoApiPath}/spaces/${allSpaceId}/contracts/${contractId}`;
    const api_key = bitlockVoApiKey;
    const headers = {
      'x-vo-api-key': api_key,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    };
    const method = 'GET';

    let propertyData = {} as Property;
    await fetch(endpoint, {
      method,
      headers,
      mode: 'cors'
    })
      .then(res => {
        if (res.status !== 200) {
          dispatch(Actions.failedSetProperty());
          return;
        }
        res.json().then(json => {
          propertyData = {
            allSpaceId: allSpaceId,
            contractId: contractId,
            applicationStatus: json.data.applicationStatus,
            hasElectricContract: json.data.hasElectricContract,
            occupyScheduledDate: new Date(json.data.occupyScheduledDate),
            postCode: json.data.postCode,
            address: json.data.address,
            prefecture: PrefectureJPMap[json.data.prefecture],
            city: json.data.city,
            afterCity: json.data.afterCity,
            buildingName: json.data.buildingName,
            name: json.data.name,
            code: json.data.code,
            sumamoruCode: json.data.sumamoruCode,
            sumamoruPlan: json.data.sumamoruPlan,
            status: json.data.status,
            managementCompanyAddress: json.data.managementCompanyAddress,
            managementCompanyName: json.data.managementCompanyName,
            managementCompanyPhoneNumber:
              json.data.managementCompanyPhoneNumber,
            sumamoruInstallAppTypes: json.data.sumamoruInstallAppTypes,
            sumamoruProjectType: json.data.sumamoruProjectType
          };
          dispatch(Actions.setProperty(propertyData));
        });
      })
      .catch(error => {
        console.error(error);
        dispatch(Actions.failedSetProperty());
      });
  } catch (error) {
    console.error(error);
    dispatch(Actions.failedSetProperty());
  }
};
