import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { Path } from '../../constants/path';
import TextToTerm from '../../components/atoms/Terms/TextToTerm';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box'
    },
    managementContainer: {
      backgroundColor: '#ECEFF1',
      padding: 20,
      boxSizing: 'border-box',
      '& p': {
        fontSize: 15,
        color: 'var(--color-gray-1)',
        lineHeight: '22px',
        margin: 0
      }
    },
    managementHeading: {
      color: '#828282 !important',
      fontWeight: 'bold',
      marginBottom: '8px !important'
    },
    body: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '21px'
    }
  })
);

const text = `

会社名
大阪ガス株式会社

代表者
代表取締役社長 藤原　正隆

所在地
大阪府大阪市中央区平野町４－１－２

電話番号
０１２０－０－９４８１７

Eメールアドレス
auto-reply@mail.sumamoru.entryservice.jp

商品・役務の提供時期
・電気…お客さまの需給契約の申込みを承諾したときに需給開始予定日をお知らせし、必要なお手続きを経たのち需給開始予定日から供給します。
・ガス…開栓のお申込みを受けた日に合意した日より供給します。
・駆けつけサービス/雑誌・マンガ読み放題サービス…サービスのお申込みを受けた日に合意した日より提供します。

お申込み有効期限
お客さまがサービスの提供を受けることを希望する日の前日まで

商品・役務の対価、対価の支払時期及び方法、提供条件
・電気の供給における電気の料金その他の供給条件は、電気供給約款によります。詳細は以下をご覧ください。
https://home.osakagas.co.jp/electricity/clause.html
・都市ガスの供給におけるガスの料金その他の供給条件は、一般ガス供給約款または基本約款および個別約款によります。詳細は以下をご覧ください。
https://home.osakagas.co.jp/price/menu/clause/index.html
・駆けつけサービス/雑誌・マンガ読み放題サービスの料金その他の供給条件は、電気供給約款によります。詳細は以下をご覧ください。
https://home.osakagas.co.jp/electricity/clause.html

その他の商品の販売条件、役務の提供条件
電気（スマモル賃貸プランに限ります。）および駆けつけサービス/雑誌・マンガ読み放題サービスは、当社が指定する、物件の管理等を行う事業者からの紹介があり、当社が適当と判断した場合にのみご利用いただけます。`;

const TokushohoViewn: React.FC = () => {
  const styles = useStyles({});
  return (
    <div className={styles.container}>
      <h1>特定商取引法に基づく表示</h1>
      <TextToTerm body={text} />
    </div>
  );
};

export default TokushohoViewn;
