import React from 'react';

/**
 * 別表
 * @returns 
 */
const TableTerm: React.FC = () => {
    return (
        <>
        <h2>（別表）</h2>
        <h3>[お客さまがご契約される会社]</h3>
        <table>
          <tbody>
            <tr>
              <th>名称</th>
              <td>大阪ガス株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>〒541-0046　大阪市中央区平野町4丁目1番2号</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                [ご契約に関するお問い合わせ窓口]
                <br />
                TEL 06-6205-3692[平日9～17時　土・日・祝・年末年始を除く]
              </td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役社長　藤原　正隆</td>
            </tr>
          </tbody>
        </table>
        <h3>[警備サービス提供会社]</h3>
        <table>
          <tbody>
            <tr>
              <th>名称</th>
              <td>大阪ガスセキュリティサービス株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>〒532-0024 大阪市淀川区十三本町3-6-35</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>
                [駆けつけのご用命〈24時間受付（年中無休）〉]
                <br />
                TEL0120-750-082
              </td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役社長　遠山　雅夫</td>
            </tr>
          </tbody>
        </table>
        <span>※対象物件の住所によっては、駆けつけ対応を大阪ガスセキュリティサービス株式会社から綜合警備保障株式会社に委託する場合があります。</span>
        <table>
          <tbody>
            <tr>
              <th>名称</th>
              <td>綜合警備保障株式会社</td>
            </tr>
            <tr>
              <th>所在地</th>
              <td>〒107-8511　東京都港区元赤坂1-6-6</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>TEL 03-3470-6811(代表)</td>
            </tr>
            <tr>
              <th>代表者</th>
              <td>代表取締役社長　青山　幸恭</td>
            </tr>
          </tbody>
        </table>
        <h3>[出動に関する警備員の情報]</h3>
        <table>
          <tbody>
            <tr>
              <th>人数および担当業務</th>
              <td>
                ①契約対象物件からのかけつけ依頼の受付業務<br />
                　：基地局に常時１名以上配置<br />
                ②かけつけ依頼時の契約対象物件への派遣要員<br />
                　：待機所毎に１名以上配置
              </td>
            </tr>
            <tr>
              <th>知識および技能</th>
              <td>警備業法に基づく教育、並びにOSS独自の教育、訓練を実施</td>
            </tr>
            <tr>
              <th>服装</th>
              <td>指定の制服を着用</td>
            </tr>
            <tr>
              <th>使用する機器・機材</th>
              <td>
                車両、無線機、特殊警戒棒、ヘルメット、防刃チョッキ、携帯電話、その他
              </td>
            </tr>
          </tbody>
        </table>
        </>
    );
}

export default TableTerm;