enum ActionType {
  updateSigninIdentifyValue = 'UPDATE_SIGNIN_IDENTIFY_VALUE',
  validateSigninIdentifyValue = 'VALIDATE_SIGNIN_IDENTIFY_VALUE',
  updateSigninPassword = 'UPDATE_SIGNIN_PASSWORD',
  validateSigninPassword = 'VALIDATE_SIGNIN_PASSWORD',
  startToSignin = 'START_TO_SIGNIN',
  signinSuccess = 'SIGNIN_SUCCESS',
  signinFailed = 'SIGNIN_FAILED',
  clearSigninStatus = 'CLEAR_SIGNIN_STATUS'
}

export type SigninErrorState =
  | 'invalid'
  | 'not_found'
  | 'auth_failed'
  | 'error';

export type TextFieldStatus = 'empty' | 'valid' | 'invalid';

export default ActionType;
