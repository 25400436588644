import React, { FC } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';

interface Props {
  start_gas_time: string;
  updateParameter: (key: string, value: string) => void;
  disabled:boolean;
}

export const SelectGasStartTime: FC<Props> = ({
  start_gas_time,
  updateParameter,
  disabled
}) => {
  return (
    <FormControl
      margin="normal"
      style={{ width: '100%', margin: 0 }}
      variant="outlined"
    >
      <Select
        value={start_gas_time}
        onChange={e =>
          updateParameter('start_gas_time', e.target.value as string)
        }
        displayEmpty
        disabled={disabled}
      >
        <MenuItem value="" disabled>
          希望時間帯
        </MenuItem>
        <MenuItem value={'9:00-12:00'}>9:00-12:00</MenuItem>
        <MenuItem value={'13:00-15:00'}>13:00-15:00</MenuItem>
        <MenuItem value={'15:00-17:00'}>15:00-17:00</MenuItem>
      </Select>
    </FormControl>
  );
};
