import ActionType from './types';
import { ActionWithPayload } from '../utils/types';
import { Property } from './types';

export const startToFetchProperty = (): ActionWithPayload<
  ActionType.startToFetchProperty,
  {}
> => ({
  type: ActionType.startToFetchProperty,
  payload: {}
});

export const setProperty = (
  property: Property
): ActionWithPayload<ActionType.setProperty, Property> => ({
  type: ActionType.setProperty,
  payload: property
});

export const failedSetProperty = (): ActionWithPayload<
  ActionType.failedSetProperty,
  {}
> => ({
  type: ActionType.failedSetProperty,
  payload: {}
});

export const setAllSpaceId = (
  id: string
): ActionWithPayload<ActionType.setAllSpaceId, string> => ({
  type: ActionType.setAllSpaceId,
  payload: id
});

export const setContractId = (
  id: string
): ActionWithPayload<ActionType.setContractId, string> => ({
  type: ActionType.setContractId,
  payload: id
});

export type PropertyAction =
  | ReturnType<typeof startToFetchProperty>
  | ReturnType<typeof setProperty>
  | ReturnType<typeof failedSetProperty>
  | ReturnType<typeof setAllSpaceId>
  | ReturnType<typeof setContractId>;
