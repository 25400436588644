import { AppState } from '../store';
import * as modules from '../modules/identification';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { updateParameter } from '../modules/identification/actions';
import { connect } from 'react-redux';
import IdentificationByBirth from '../components/IdentificationByBirth';
import * as propertyActions from '../modules/property/actions';
import * as Actions from '../modules/index/actions';

export const mapStateToProps = (state: AppState) => {
  return {
    birthday: modules.Selectors.getBirthday(state),
    status: state.identification.status
  };
};

export const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, {}, AnyAction>
) => ({
  setQueryParams: (allSpaceID: string, contractId: string) => {
    dispatch(propertyActions.setAllSpaceId(allSpaceID));
    dispatch(propertyActions.setContractId(contractId));
    dispatch(Actions.setQueryParams());
  },
  updateParameter: (
    key: string,
    value: string | number | boolean | undefined
  ) => dispatch(updateParameter(key, value)),
  verifyIdentification: () =>
    dispatch(modules.operations.verifyIdentification())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IdentificationByBirth);
