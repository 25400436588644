import { AppState } from '../../store';
import { SignupMethod, SignupStatus, TextFieldStatus } from './types';

export const getEmail = ({ signUpForm }: AppState): string => {
  return signUpForm.email.value;
};

export const getEmailStatus = ({ signUpForm }: AppState): TextFieldStatus => {
  return signUpForm.email.status;
};

export const getPhoneNumber = ({ signUpForm }: AppState): string => {
  return signUpForm.phoneNumber.value;
};

export const getPhoneNumberStatus = ({
  signUpForm
}: AppState): TextFieldStatus => {
  return signUpForm.phoneNumber.status;
};

export const getPassword = ({ signUpForm }: AppState): string => {
  return signUpForm.password.value;
};

export const getPasswordStatus = ({
  signUpForm
}: AppState): TextFieldStatus => {
  return signUpForm.password.status;
};

export const getStatus = ({ signUpForm }: AppState): SignupStatus => {
  return signUpForm.status;
};

export const getSignupMethod = ({ signUpForm }: AppState): SignupMethod => {
  return signUpForm.signupMethod;
};
