import React from 'react';
import TextToTerm from './TextToTerm';

const yakkan = `
第１条 （目的）
この約款は、大阪ガス株式会社（以下、「当社」といいます。）が、「駆けつけサービス」をご契約いただくお客さま（以下、「お客さま」といいます。）に対して、「駆けつけサービス」（以下、「本サービス」といいます。）の契約内容（以下、「本契約」といいます。）を定めるものです。

第２条 （サービス内容）
本サービスの内容は、次のとおりです。
・お客さまのご自宅（お客さまがお引越しに際し新たにご契約される物件を指し、以下、「対象物件」といいます。）の防犯に関してご懸念が生じた時に、お客さまからのお電話によるご依頼に基づき、警備員がかけつけるサービス（以下、「警備員かけつけ」といいます。なお、本サービスは、犯罪の未然防止を約束するサービスではございません。）
・対象物件のスマートロックの解錠に関するトラブルについて、お客さまからのお電話によるご依頼に基づき、ワンタイムパスワードを用いて対応するサービス（以下、「スマートロックの解錠サポート」といいます。）
ただし、対象物件の所在地その他理由により、一部のサービスの提供を行えない場合があります。

第３条 （お客さま）
本サービスは、お客さまのみが利用することができます。また、対象物件と異なる物件では利用することはできません。
２．本サービスを利用する場合、運転免許証等の公的機関が発行した身分証明書による本人確認が必要となる場合があります。

第４条 （提供期間）
本サービスの提供期間は、原則として対象物件への入居日から2年間とします。ただし、お客さまに当社の電気料金メニュー（「スマモル賃貸プラン」に限ります。以下、「スマモル賃貸プラン」といいます。）の適用がある場合は、入居日から2年を経過した以降も、その契約が継続する限り本サービスの提供は継続されます。上記にかかわらず、退居に伴う解約の場合、または入居日から2年を経過する前に退居される場合は、退居日をもってサービスの提供は終了されるものとします。

第５条 （サービス提供の中止）
お客さまにおいて次の事由が生じた場合、当社はお客さまに何らの通知を要することなく本サービスの提供を中止することができます。
①本サービスの目的に反した使用が繰り返される等、お客さまが正常な方法でご使用いただけない場合で、当社からお客さまに改善を申し入れたにもかかわらず、状況が改善されないとき
②お客さまが、暴力団、暴力団関連企業をはじめとする反社会的勢力またはその構成員または準構成員に該当することが判明した場合
③お客さまが、自らまたは第三者を利用して、次のいずれかに該当する行為をした場合
・暴力的な要求行為
・法的な責任を超えた不当な要求行為
・取引に関して、脅迫的な言動をし、または暴力を用いる行為
・風説を流布し、偽計を用い、または威力を用いて当社の信用を毀損し、または当社の業務を妨害する行為
・その他上記に準ずる行為
④本約款の各条項に違反したとき
⑤前各号に掲げる事由の他、当社が本サービスの提供上支障があると判断したとき

第６条 （業務委託等）
当社は、本サービスの各種業務等について、業務の一部を委託することがあります。
２．当社は、業務を委託する場合に、当社から業務委託先に対し、必要な範囲でお客さま情報を提供することがあります。この場合、当社は、業務委託先との間でお客さま情報の取り扱いに関する契約を結ぶ等、適切な監督を行います。

第７条 （対象物件への立ち入り等）
当社または当社の指定する第三者は、本サービスの遂行に必要がある場合には、対象物件に立ち入ることができるものとし、お客さまは、当該立ち入り、工事に正当な事由がない限り、立ち入りを承諾するものとする。
２．対象物件を第三者へ貸借している場合で、お客さまが貸主の場合、お客さまは、対象物件の借主に対して、お客さまの責任にて、当社が本サービスの遂行のため対象物件に立ち入ることに関して承諾を得るものとします。
３．前項でお客さまが借主の場合、お客さまは、対象物件の貸主に対して、お客さまの責任にて、当社が本サービスの遂行のため対象物件に立ち入ることに関して承諾を得るものとします。
４．当社は、お客さまが第2 項および第3 項に定める承諾を得なかったことにより生じた損害については一切責任を負いません。

第８条 （サービスの停止）
当社は、以下の各号に定める場合で本サービスの提供を継続できないと判断した場合には、本サービスの提供を停止します。停止期間中、当社は本サービスの提供についての義務を一切まぬがれるものとし、停止に起因する損害について責めを負わないものとします。
天災地変、暴動、通信回線（お客さまが設置された通信機器等を含みます）の障害、停電、その他不可抗力による損壊等が生じた場合
本サービス提供に必要なシステムに異常が発生した場合
お客さま以外の依頼
スマモル賃貸プランの適用がある場合で、スマモル賃貸プランの料金のお支払いがない場合
申込内容に変更が生じたにもかかわらず、お客さまが当社への連絡を行わなかった場合
本サービス対象の不動産自体の瑕疵もしくはお客さまの管理上の瑕疵があった場合
本サービスの継続に通常必要となる作業を行う場合

第９条 （損害賠償）
当社は、本サービスの提供にあたって、当社の責めに帰すべき事由によりお客さまに損害を与えた場合、お客さまに対して以下のとおり、当該損害の賠償をいたします。但し、警備員かけつけは犯罪の未然防止を約束するサービスではないため、犯罪によりお客さまに発生した損害については、当社による損害賠償の対象外といたします。
２．当社に対して損害の賠償を請求する場合、お客さまは損害の発生の事実を知った後、遅滞なく当社に対して通知し、当社は現場確認や公的機関等から発行された証明書等により損害を確認したうえで、損害賠償を行います。ただし、その限度額は、1事故1億円までとします。
３．本条の規定にかかわらず、お客さまが前項の通知を怠った場合は、賠償に関する諸手続きが行えない場合があります。

第１０条 （協議事項）
お客さまと当社は、本契約に定めのない事項については、法令および商慣習に従うほか、誠意をもって協議いたします。

第１１条 （守秘義務）
お客さまおよび当社は、本サービスを通じて知り得た秘密情報（技術情報、お客さま情報等）を善良なる管理者の注意をもって管理し、第三者へ漏らしてはなりません。このことは本サービス終了後も同様とします。

第１２条 （お客さま情報の取り扱い）
当社はお客さまの個人情報を当社ホームページに記載の「プライバシーポリシー」および別添「お客さま情報の利用目的について」に基づき取り扱います。お客さまの個人情報の利用目的については、「プライバシーポリシー」および「お客さま情報の利用目的について」をご参照ください。
２．当社が本サービスの提供の一部を委託した場合は、委託先へ必要な範囲で個人情報を提供、開示する場合があります。この場合、当社は委託先との間で取扱いに関する契約を結ぶ等、適切な監督を行います。
３．当社は、業務品質向上等のため通話録音をさせていただくことがありますが、お客さま・緊急連絡先からの録音内容の確認のご依頼には対応いたしません。

第１３条 （権利譲渡の禁止）
お客さまは当社の同意なしに本サービスに関する権利・義務を第三者に譲渡できません。

第１４条 （相互協力）
お客さまおよび当社は互いに協力し、信義を守り誠実に本規定に従うものとし、本規程に定めのない事項および疑義の生じた事項については、お客さまおよび当社の協議により誠意をもって解決するものとします。

第１５条 （管轄裁判所）
本契約または本サービスの提供に関して、訴訟の必要が生じた場合には、大阪地方裁判所を専属的合意管轄裁判所とします。

第１６条 （改定）
当社は、本サービスの約款を予告なく変更させていただくことがあります。なお、変更後の約款につきましては、当社のホームページ等にお知らせいたします。

第１７条 （効力開始）
本約款は、2021年11月1日から有効とします。
`;

const keibi = `
第１８条（警備員の出動）
１．当社は警備員の出動業務を大阪ガスセキュリティサービス株式会社（以下、「OSS」といいます。）等に委託します。
２．当社は出動業務の委託先に対し、必要な範囲でお客さま情報を提供します。この場合、当社は委託先との間でお客さま情報の取り扱いに関する契約を結ぶ等、適切な監督を行います。
３．本サービスにおける警備員かけつけの実施日は、お客さまの申し込みにより、実際に駆けつけを実施する日とします。また、一件当たりの所要時間の目安は、5分～15分程度となります。ただし、案件により前後する場合があります。

第１９条 （警備員かけつけ時の措置）
　お客さまの依頼をいただいた場合、監視センターの指令により警備員は対象物件に出動し、対象物件の建物外周より目視可能な範囲内で異常発生の有無を確認（以下、「外周点検」といいます。）します。
　異常発生が認められた場合には、警察、消防等へ通報するなどお客さまの被る損害の拡大防止のための必要な措置をとります。
２．外周点検のため、警備員は、お客さまの承諾なく対象物件の敷地内に立ち入ることができるものとします。但し、門扉に鍵がかかっているなどの場合は敷地外からの点検といたします。
３．異常事態発生の場合、OSSは、お客さまに対して、緊急連絡を行います。
４．天候・交通状況・警備員の出動状況等により現場到着に相応の時間を要する場合があります。
５．警備員かけつけサービスは、防犯に関するご心配時の警備員かけつけサポートであり、日常生活の雑用等を警備員に依頼することはできません。
[警備員に依頼できない行為の例]
・人の救急
・人の身辺警備
・ガスの消し忘れ対応
・ポストの郵便物の整理・回収
・植木の水やり
・ペットの餌やり

第２０条 （警備員かけつけの報告）
　警備員が外周点検を行った場合、警備員は点検報告書を対象物件へ投函します。また、監視センターは、必要に応じて警備員が外周点検を行った結果を登録電話番号に通知します。

第２１条 （警備員かけつけ出動の料金）
　本サービスの提供期間中の警備員かけつけについては、年間1回までは無償とさせていただきます。年間2回目以降の、警備員かけつけに対しては、出動に要する費用（１回の出動あたり５，５００円（税込））をお客さまに従量サービス料金として請求します。なお、入居日から起算して1年を「年間」とし、従量サービス料金の要否を判断するための年間の出動回数については、第２４条のスマートロックの開錠サポートのための警備員の出動と合算してカウントするものとします。
２．従量サービス料金は、出動の指示を行った時点で発生するものとし、お客さまが出動をキャンセル・変更された場合でもお支払いいただきます。

第２２条（出動予約）
　お客さまは、出動を希望される日（以下、「出動日」といいます。）の14日前まで当社に連絡することにより、出動を予約することができます。２．出動可能時間は以下の時間帯とし、時間指定はできません。
①６：００～１２：００
②１３：００～１９：００
③１９：００～２４：００
３．出動予約後にキャンセルを希望される場合は、当社に出動日の前日までにご連絡いただきます。
４．出動日当日のキャンセルにつきましては、第２１条第1項に規定する費用を申し受けます。

第２３条 （免責）
　警備員のかけつけは、犯罪の早期発見および早期対応による拡大防止を目的とするものであり、犯罪の未然防止を保証するものではありません。したがって、当社はお客さまが犯罪により被られた損害について、本契約に基づいて賠償する責めを負いません。これらの損害に対する備えとして、お客さまご自身で盗難保険等に加入していただきます。
［当社が賠償する責めを負わない損害の例］
① 天災地変、暴動、通信回線（お客さまが設置された通信機器等を含みます）の障害、停電、その他不可抗力による損壊等により当社がお客さまに本サービスの提供ができなくなったことに起因する損害
② 対象物件自体の瑕疵もしくはお客さまの管理上の瑕疵またはお客さまの故意や重過失により生じた損害
③ 地震・火災などの災害により、またはこれらの災害に起因する損害
④ 窃盗または強盗のために生じた火災または爆発等による損害
⑤ 窃盗・強盗・火災発生等にともなう 、OSS、消防隊、警察隊等による入口扉・窓等破壊等の損害
⑥ 警備員かけつけサービスとの因果関係が認められない損害
⑦ 警備員かけつけサービスが停止されている間に生じた損害
⑧ 申込内容に変更が生じたにもかかわらず、お客さまが当社への連絡を行わなかったことに起因する損害
⑨ その他客観的に判断して賠償免責に相当する損害
２．天候・交通状況・作業員の作業状況等により現場到着に相応の時間を要する場合があります。
`;

/**
 * OSS駆けつけサービスの約款
 * プランによっては使わなくなる可能性があるので外出し
 * バージョン発生する
 * @returns
 */
const OSSTermV2: React.FC = () => {
  return (
    <>
      <h1 id="kaketsuke">駆けつけサービス契約約款</h1>
      <TextToTerm body={yakkan} />
      <h2 id="keibi">＜警備員かけつけに関する約款＞</h2>
      <TextToTerm body={keibi} />
    </>
  );
};

export default OSSTermV2;
