import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { bitlockVoApiKey, bitlockVoApiPath } from '../../config/baseConfig';
import { Property, SumamoruPlan } from '../../modules/property/types';
import { PrefectureJPMap } from '../../utils/address-types';
import { Loading } from '../../components/atoms/Loading';
import ExplanationMattersDefaultPlan from '../../components/atoms/ExplanationMattersDefaultPlan';
import ExplanationMattersViewnPlan from '../../components/atoms/ExplanationMattersViewnPlan';
import ExplanationMattersWLockPlan from '../../components/atoms/ExplanationMattersWLockPlan';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxWidth: 600,
      margin: '40px auto',
      padding: 20,
      boxSizing: 'border-box'
    },
    managementContainer: {
      backgroundColor: '#ECEFF1',
      padding: 20,
      boxSizing: 'border-box',
      '& p': {
        fontSize: 15,
        color: 'var(--color-gray-1)',
        lineHeight: '22px',
        margin: 0
      }
    },
    managementHeading: {
      color: '#828282 !important',
      fontWeight: 'bold',
      marginBottom: '8px !important'
    },
    body: {
      fontSize: 14,
      color: 'var(--color-text)',
      lineHeight: '21px'
    }
  })
);

type status = 'not_load' | 'loading' | 'load' | 'error';
type Props = {
  sumamoruPlan?: SumamoruPlan
};

const ExplanationMatters: React.FC<Props> = (props) => {
  const {sumamoruPlan} = props;
  const styles = useStyles({});
  const history = useHistory();
  const [status, setStatus] = useState<status>('not_load');
  const [propertyData, setPropertyData] = useState<Property>();

  useEffect(() => {
    if (status === 'not_load') {
      const params = new URLSearchParams(history.location.search);
      const all_space_id = params.get('s') || '';
      const contract_id = params.get('c') || '';

      if (all_space_id === '' || contract_id === '') {
        setStatus('error');
        return;
      }

      const endpoint = `${bitlockVoApiPath}/spaces/${all_space_id}/contracts/${contract_id}`;
      const api_key = bitlockVoApiKey;
      const headers = {
        'x-vo-api-key': api_key,
        Accept: 'application/json',
        'Content-Type': 'application/json'
      };
      const method = 'GET';

      setStatus('loading');

      fetch(endpoint, {
        method,
        headers,
        mode: 'cors'
      })
        .then(res => {
          if (res.status !== 200) {
            setStatus('error');
            return;
          }
          res.json().then(json => {
            setPropertyData({
              allSpaceId: all_space_id,
              contractId: contract_id,
              applicationStatus: json.data.applicationStatus,
              hasElectricContract: json.data.hasElectricContract,
              occupyScheduledDate: new Date(json.data.occupyScheduledDate),
              postCode: json.data.postCode,
              address: json.data.address,
              prefecture: PrefectureJPMap[json.data.prefecture],
              city: json.data.city,
              afterCity: json.data.afterCity,
              buildingName: json.data.buildingName,
              name: json.data.name,
              code: json.data.code,
              sumamoruCode: json.data.sumamoruCode,
              sumamoruPlan: json.data.sumamoruPlan,
              status: json.data.status,
              managementCompanyAddress: json.data.managementCompanyAddress,
              managementCompanyName: json.data.managementCompanyName,
              managementCompanyPhoneNumber:
                json.data.managementCompanyPhoneNumber,
              sumamoruInstallAppTypes: json.data.sumamoruInstallAppTypes
            });
            setStatus('load');
          });
        })
        .catch(error => {
          console.error(error);
          setStatus('error');
        });
    }
  }, [status, history]);

  return (
    <div className={styles.container}>
      <h1>重要事項説明書</h1>
      {sumamoruPlan === SumamoruPlan.OG_WLock && (
        <ExplanationMattersWLockPlan styles={styles.body} />
      )}
      {sumamoruPlan === SumamoruPlan.OG_Viewn && (
        <ExplanationMattersViewnPlan styles={styles.body} />
      )}
      {(sumamoruPlan === SumamoruPlan.OG_Default || !sumamoruPlan) && (
        <ExplanationMattersDefaultPlan styles={styles.body} />
      )}
      {(status === 'loading' || status === 'load') && (
        <div className={styles.managementContainer}>
          {status === 'loading' && <Loading />}
          {status === 'load' && (
            <>
              <p className={styles.managementHeading}>販売店(紹介業者)</p>
              <p>{propertyData ? propertyData.managementCompanyName : '-'}</p>
              <p>
                {propertyData ? propertyData.managementCompanyAddress : '-'}
              </p>
              <p>
                TEL:{' '}
                {propertyData ? propertyData.managementCompanyPhoneNumber : '-'}
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ExplanationMatters;
