import React from 'react';
import { Container } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import notFound from '../../static/images/notFound.png';
import electric from '../../static/images/electric.png';

const useStyles = makeStyles(() =>
  createStyles({
    background: {
      background: '#03A9F4',
      minHeight: '100vh',
      textAlign: 'center'
    },
    container: {
      margin: 0,
      fontFamily: 'Noto Sans CJK JP',
      fontStyle: 'normal',
      padding: '100px 0',
      '@media (min-width:768px)': {
        padding: '180px 0'
      }
    },
    notFoundImage: {
      width: '75%',
      margin: '16px auto',
      '@media (min-width:768px)': {
        width: 310
      }
    },
    electricImage: {
      width: '100%',
      margin: '16px auto',
      '@media (min-width:768px)': {
        width: 375
      }
    },
    heading: {
      fontWeight: 'bold',
      fontSize: '18px',
      margin: '0',
      textAlign: 'center',
      letterSpacing: '0.5px',
      color: '#FFFFFF'
    },
    caption: {
      width: '195px',
      margin: '16px auto',
      fontSize: '14px',
      lineHeight: '21px',
      textAlign: 'center',
      color: '#FFFFFF'
    }
  })
);

const NotFoundTemplate: React.FC = () => {
  const styles = useStyles({});

  return (
    <div className={styles.background}>
      <Container className={styles.container}>
        <img className={styles.notFoundImage} src={notFound} alt={'404'} />
        <h2 className={styles.heading}>指定されたページが見つかりません</h2>
        <p className={styles.caption}>
          URLが正しく指定されているかご確認ください
        </p>
        <img className={styles.electricImage} src={electric} alt={'electric'} />
      </Container>
    </div>
  );
};

export default NotFoundTemplate;
