import ActionTypes, { submitErrorStatus } from './types';
import { ApplicatonAction } from './actions';
import * as lodash from 'lodash';

export interface ApplyFormParamaterState {
  use_og_gas: string;
  sumamoru_plan?: string;
  family_name: string;
  first_name: string;
  family_name_kana: string;
  first_name_kana: string;
  phone_number: string;
  phone_number_when_move: string;
  phone_number_after_move: string;
  email: string;
  email_confirmation: string;
  birthday: string;
  pay_method: string;
  pay_method_text: string;
  move_plan_date: string;
  start_power_date: string;
  start_gas_date: string;
  start_gas_time: string;
  isCoodinatedGasStartDate: boolean;
  gender: string;
  isSameEmail: boolean;
  isPhoneNumberError: boolean;
  isPhoneNumberWhenMoveError: boolean;
  isEmailMatch: boolean;
}

type SigninStatusState =
  | 'not_submit'
  | 'submit_loading'
  | 'submit'
  | submitErrorStatus;

export interface ApplyFormState {
  parameters: ApplyFormParamaterState;
  status: SigninStatusState;
}

export const initialState: () => ApplyFormState = () => {
  return {
    status: 'not_submit',
    parameters: {
      use_og_gas: '1',
      family_name: '',
      first_name: '',
      family_name_kana: '',
      first_name_kana: '',
      phone_number: '',
      phone_number_when_move: '',
      phone_number_after_move: '',
      email: '',
      email_confirmation: '',
      birthday: '19800101',
      pay_method: '0',
      pay_method_text: '',
      move_plan_date: '',
      start_power_date: '',
      start_gas_date: '',
      start_gas_time: '',
      isCoodinatedGasStartDate: false,
      gender: '',
      isSameEmail: true,
      isPhoneNumberError: false,
      isPhoneNumberWhenMoveError: false,
      isEmailMatch: false
    }
  };
};

const reducer = (
  state: ApplyFormState = initialState(),
  action: ApplicatonAction
): ApplyFormState => {
  switch (action.type) {
    case ActionTypes.submitParamater:
      const newSubmitState = { ...state };
      newSubmitState.status = 'submit_loading';
      return newSubmitState;
    case ActionTypes.successToSubmitParamater:
      const newSubmitSuccessState = { ...state };
      newSubmitSuccessState.status = 'submit';
      return newSubmitSuccessState;
    case ActionTypes.failedToSubmitParamater:
      const newSubmitFailedState = { ...state };
      newSubmitFailedState.status = action.payload;
      return newSubmitFailedState;
    case ActionTypes.updateParamater:
      const parameters = lodash.clone(state.parameters);
      switch (action.payload.key) {
        case 'use_og_gas':
          parameters.use_og_gas = action.payload.value as string;
          break;
        case 'start_power_date':
          parameters.start_power_date = action.payload.value as string;
          break;
        case 'start_gas_date':
          parameters.start_gas_date = action.payload.value as string;
          break;
        case 'start_gas_time':
          parameters.start_gas_time = action.payload.value as string;
          break;
        case 'isCoodinatedGasStartDate':
          parameters.isCoodinatedGasStartDate = action.payload.value as boolean;
          break;
        case 'first_name':
          parameters.first_name = action.payload.value as string;
          break;
        case 'family_name':
          parameters.family_name = action.payload.value as string;
          break;
        case 'first_name_kana':
          parameters.first_name_kana = action.payload.value as string;
          break;
        case 'family_name_kana':
          parameters.family_name_kana = action.payload.value as string;
          break;
        case 'phone_number':
          parameters.phone_number = action.payload.value as string;
          break;
        case 'phone_number_when_move':
          parameters.phone_number_when_move = action.payload.value as string;
          break;
        case 'birthday':
          parameters.birthday = action.payload.value as string;
          break;
        case 'email':
          parameters.email = action.payload.value as string;
          break;
        case 'email_confirmation':
          parameters.email_confirmation = action.payload.value as string;
          break;
        case 'pay_method':
          parameters.pay_method = action.payload.value as string;
          break;
        case 'gender':
          parameters.gender = action.payload.value as string;
          break;
        case 'isSameEmail':
          parameters.isSameEmail = action.payload.value as boolean;
          break;
        case 'isPhoneNumberError':
          parameters.isPhoneNumberError = action.payload.value as boolean;
          break;
        case 'isPhoneNumberWhenMoveError':
          parameters.isPhoneNumberWhenMoveError = action.payload
            .value as boolean;
          break;
        case 'isEmailMatch':
          parameters.isEmailMatch = action.payload.value as boolean;
          break;
        default:
          break;
      }
      return {
        ...state,
        parameters
      };
    default:
      return state;
  }
};

export default reducer;
