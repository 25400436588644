import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Container,
  FormGroup,
  Grid,
  Modal,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { Path } from '../constants/path';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { BaseFab } from './BaseFab';
import { TermsAgreementCheckbox } from './TermsAgreementCheckbox';
import { SumamoruPlan } from '../modules/property/types';

const useStyles = makeStyles(
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    paper: {
      backgroundColor: 'var(--color-white)',
      width: '100%',
      margin: '0 20px',
      borderRadius: 10,
      '@media (min-width:768px)': {
        width: '50%'
      }
    },
    paperHeadingContainer: {
      backgroundColor: 'var(--color-key)',
      padding: '20px 16px',
      fontWeight: 300,
      fontStyle: 'normal',
      borderRadius: '10px 10px 0 0',
      position: 'relative'
    },
    paperHeading: {
      color: 'var(--color-white)',
      fontSize: 14,
      letterSpacing: '0.08em',
      margin: 0
    },
    closeIconButton: {
      position: 'absolute',
      top: 6,
      right: 10
    },
    closeIcon: {
      color: 'var(--color-white)'
    },
    agreementButton: {
      backgroundColor: 'var(--color-key)',
      color: 'var(--color-white)',
      boxShadow: 'none',
      width: '100%',
      margin: '0 auto',
      '@media (min-width:768px)': {
        fontSize: '16px',
        letterSpacing: '0.75px',
        fontWeight: '500',
        width: '60%',
        marginTop: '20px'
      }
    },
    cancelButtonContainer: {
      margin: '20px auto',
      textAlign: 'center',
      '@media (min-width:768px)': {
        fontSize: '16px',
        letterSpacing: '0.75px',
        fontWeight: '500',
        width: '60%'
      }
    },
    modalShopLabelContainer: {
      backgroundColor: 'var(--color-background-gray)',
      padding: '8px 12px',
      fontSize: 12,
      width: 'calc(100% - 24px)',
      margin: '16px 12px'
    },
    modalShopLabel: {
      margin: 0,
      color: 'var(--color-gray-3)',
      fontWeight: 500
    },
    modalShopDetails: {
      color: 'var(--color-gray-1)',
      lineHeight: '17px',
      margin: '8px 0 0',
      '@media (min-width:768px)': {
        marginTop: 0
      }
    },
    containerRoot: {
      paddingTop: 16
    },
    termsContainer: {
      height: 300,
      overflowY: 'scroll'
    },
    terms: {
      margin: 0,
      fontSize: 12,
      color: 'var(--color-text)',
      lineHeight: '120%',
      // height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    bottomContainer: {
      textAlign: 'center'
    },
    termsForm: {
      height: 'calc(100% - 80px)',
      justifyContent: 'center',
      paddingLeft: 16
    },
    additionalExplanation: {
      color: 'var(--color-error)'
    },
    confirms: {
      margin: 16
    }
  })
);

interface Props {
  modalOpen: boolean;
  setModalOpen: (e: boolean) => void;
  management_company_name: string;
  management_company_address: string;
  management_company_phonenumber: string;
  sumamoruPlan?: string;
}

type ExplanationMattersChecked = {
  electricContract: boolean;
  privacyPolicy: boolean;
  sumamoruPlanOutline: boolean;
};

type TermsAgreement = {
  label: string;
  type: keyof ExplanationMattersChecked;
  link: string;
};

const ApplicationModal: FC<Props> = ({
  modalOpen,
  setModalOpen,
  management_company_name,
  management_company_address,
  management_company_phonenumber,
  sumamoruPlan
}) => {
  const styles = useStyles();
  const history = useHistory();

  const [screenWidth, setScreenWidth] = useState(0);

  const [checked, setChecked] = useState<ExplanationMattersChecked>({
    electricContract: false,
    privacyPolicy: false,
    sumamoruPlanOutline: false
  });
  const canGoToConfirm = useMemo((): boolean => {
    return (
      checked.electricContract &&
      checked.privacyPolicy &&
      checked.sumamoruPlanOutline
    );
  }, [checked]);

  const onCheck = useCallback(
    (type: keyof ExplanationMattersChecked) => (check: boolean) => {
      setChecked(prev => ({
        ...prev,
        [type]: check
      }));
    },
    []
  );

  const handleModalClose = () => {
    setModalOpen(false);
    setChecked({
      electricContract: false,
      privacyPolicy: false,
      sumamoruPlanOutline: false
    });
  };

  useEffect(() => {
    const width = document.getElementById('root')!.clientWidth;
    setScreenWidth(width);
  }, []);

  const handleClick = () => {
    history.push(Path.application.confirm);
  };

  const terms = useMemo((): TermsAgreement[] => {
    return [
      {
        type: 'electricContract',
        label:
          '電気需給契約　重要事項説明書（リンク先の重要事項説明書部分をご確認ください）',
        link:
          'https://home.osakagas.co.jp/electricity/common/pdf/importantmatter.pdf'
      },
      {
        type: 'privacyPolicy',
        label: 'お客さま情報の取扱いについて',
        link: 'https://www.osakagas.co.jp/info/privacy.html'
      },
      {
        type: 'sumamoruPlanOutline',
        label: 'スマモル賃貸プラン 要綱',
        link:
          'https://home.osakagas.co.jp/electricity/lp/sumamoru/pdf/outline.pdf'
      }
    ];
  }, []);

  const additionalExplanation = useMemo((): string => {
    if (sumamoruPlan === SumamoruPlan.OG_Default) {
      return '※駆けつけサービス、優待・割引サービスが付帯されたプランの要綱をご確認下さい。';
    }
    if (sumamoruPlan === SumamoruPlan.OG_Viewn) {
      return '※駆けつけサービス、雑誌・マンガ読み放題サービスが付帯されたプランの要綱をご確認下さい。';
    }
    if (sumamoruPlan === SumamoruPlan.OG_WLock) {
      return '※駆けつけサービス、ダブルロックサービスが付帯されたプランの要綱をご確認下さい。';
    }
    return '';
  }, []);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={modalOpen}
      className={styles.modal}
      onClose={() => setModalOpen(false)}
    >
      <div className={styles.paper}>
        <Container className={styles.paperHeadingContainer}>
          <h2 className={styles.paperHeading}>
            スマモル賃貸プラン 重要事項説明
          </h2>
          <IconButton
            className={styles.closeIconButton}
            onClick={handleModalClose}
          >
            <CloseIcon className={styles.closeIcon} />
          </IconButton>
        </Container>

        <Container className={styles.containerRoot}>
          <div className={styles.termsContainer}>
            <div id={'terms'} className={styles.terms}>
              <div className={styles.confirms}>
                <Typography>以下3つのリンク先をご確認ください。</Typography>
                <Typography>
                  内容に問題がなければ、画面下部のボタンから申し込み内容の確認へお進みください。
                </Typography>
              </div>
              <FormGroup className={styles.termsForm}>
                {terms.map((term, index) => (
                  <div key={index}>
                    <TermsAgreementCheckbox
                      checked={checked[term.type]}
                      onCheck={onCheck(term.type)}
                      label={term.label}
                      link={term.link}
                    />
                  </div>
                ))}
                <Typography className={styles.additionalExplanation}>
                  {additionalExplanation}
                </Typography>
              </FormGroup>
              <div className={styles.confirms}>
                <Typography>
                  {
                    '大阪ガスは、電気事業法に定める契約締結前及び契約締結後の書面交付について、書面でお知らせする事項を除いては、書面交付に代えて、重要事項説明書および電気供給約款のPDFファイルを当社ホームページに掲載する方法によりこれを提供します。（閲覧には最新版のPDF閲覧用ソフトをご利用ください）'
                  }
                </Typography>
              </div>
            </div>
          </div>
        </Container>

        <Container className={styles.modalShopLabelContainer}>
          {screenWidth > 768 ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <p className={styles.modalShopLabel}>販売店(紹介業者)</p>
                </Grid>
                <Grid item xs={8}>
                  <p className={styles.modalShopDetails}>
                    {management_company_name}
                    <br />
                    {management_company_address}
                    <br />
                    TEL：{management_company_phonenumber}
                  </p>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <p className={styles.modalShopLabel}>販売店(紹介業者)</p>
              <p className={styles.modalShopDetails}>
                {management_company_name}
                <br />
                {management_company_address}
                <br />
                TEL：{management_company_phonenumber}
              </p>
            </>
          )}
        </Container>

        <Container className={styles.bottomContainer}>
          <BaseFab
            className={styles.agreementButton}
            variant="extended"
            size="large"
            aria-label="add"
            disabled={!canGoToConfirm}
            onClick={handleClick}
          >
            同意して申込内容の確認へ
          </BaseFab>
          <div className={styles.cancelButtonContainer}>
            <Button onClick={handleModalClose}>キャンセル</Button>
          </div>
        </Container>
      </div>
    </Modal>
  );
};

export default ApplicationModal;
